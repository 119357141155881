import React, { useEffect, useState } from 'react'
import Astro from '../44.png';
import blog from '../blog_single1.jpg';
import Astro1 from '../cri.png';
import axios from "axios";
import Card from "react-bootstrap/Card";
import { Link, } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';


function Talktoastrologer() {
    const navigate = useNavigate();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isShown, setIsShown] = useState(false);

    const [userMobile, setUserMobile] = useState({
        phone: "",


    })
    const [allLiveAstrologers, setAllLiveAstrologer] = useState([]);


    const [userOtp, setUserOtp] = useState({

        user_pass: "",
        phone: "",
    })





    const handleClick = () => {
        // 👇️ toggle shown state
        setIsShown(current => !current);

    }




    function onFormSubmit() {

        try {
            axios.post(`http://backend.dailykundli.com/api/login_with_phone`, userMobile)
                .then((res) => {
                    const LoginData = res.data;
                    console.log(LoginData)
                    alert(res.data.phone);

                    setUserOtp({
                        ...userOtp,

                        phone: res.data.phone
                    })

                });
        } catch (error) {
            console.log(error);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }


    function onFormSubmitotp() {
        try {
            axios.post(`http://backend.dailykundli.com/api/login_with_otp`, userOtp)
                .then((res) => {
                    const LoginData = res.data;
                    console.log(LoginData);

                    if (LoginData.status == "Invalid") {
                        console.log("inside if block");
                        alert("Invalid Login Details !!!")
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    } else {




                        localStorage.setItem('sess_phone', LoginData.phone);

                        localStorage.setItem('sess_userid', LoginData.status);
                        localStorage.setItem('sess_user_type', LoginData.user_type);



                        //   window.location.assign('http://app.dailykundli.com/api/customer/astrologer_live_list?chat=1')
                        window.location.assign("http://app.dailykundli.com/" + LoginData.user_type + '/dashboard.html');
                    }
                });



        } catch (error) {
            console.log(error);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }



    }



    async function onTextFieldChange(e) {
        setUserMobile({
            ...userMobile,

            [e.target.name]: e.target.value
        })
    }
    async function onTextFieldChangeotp(e) {
        setUserOtp({
            ...userOtp,
            [e.target.name]: e.target.value
        })
    }



    useEffect(() => {
        async function getAllLiveAstrologers() {
            try {
                const allLeadsApi = await axios.get(
                    `http://backend.dailykundli.com/api/customer/astrologer_live_list?call=1`
                );
                setAllLiveAstrologer(allLeadsApi.data.data);
            } catch (error) {
                console.log("Something is Wrong -allLeads");
            }
        }

        getAllLiveAstrologers();
    }, []);
    return (


        <>


            <section className="as_breadcrum_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center" style={{ textAlign: "center" }}>
                            <h1 style={{ paddingTop: "5px" }}> Call To Astrologers</h1>

                            <ul className="breadcrumb">
                                <li><a href="/">Home</a></li>

                            </ul>
                        </div>
                    </div>
                </div>
            </section>



            <div className='row'>

                <h3 style={{ textAlign: "center", paddingTop: "2%" }}> Call with the Best Astrologers</h3>

                <p style={{ textAlign: "justify", padding: "2%", paddingLeft: "10%", paddingRight: "10%" }}>

                    In a new way to interact with astrologers, Astrotalk brings you Astrotalk Live, where you can talk to astrologers via live sessions and ask them questions for free. Astrotalk Live is a new and innovative way to talk to an astrologer face-to-face and get your queries answered while enjoying the best of astrology.
                    On Astrotalk live, anyone can get guidance from the best astrologers in India on questions spanning across topics such as marriage, career, love, health and much more.

                    Talking with astrologers through live sessions is one of the most popular features of Astrotalk, as no other app provides this unique way to consult an astrologer. Apart from just being unique, the feature is easy to use and highly interactive.
                    Accessing an astrologer on Astrotalk Live is fairly simple, and so is getting your queries answered by them. To have the best experience of live sessions, it is recommended that you ask YES and NO questions to the astrologer.
                    Also, if you like the session being delivered by an astrologer, you can even contribute to their earnings by the means of donations.
                    The Astrotalk Live feature is also available on the Astrotalk app.


                </p>




                <section className="as_about_wrapper as_padderTop80 as_padderBottom80" style={{ paddingTop: "2%" }}>
                    <div className='row'>
                        <div className='col-6'>
                            <h2 style={{ paddingLeft: "1%", }}>Call To Astrologer</h2>
                        </div>
                        <div className='col-6'>
                            <div className="as_search_wrapper" style={{ marginLeft: "10%" }}>
                                <input type="text" name="" className="form-control" id="" placeholder="Search..." />
                                <a href="#">

                                </a>
                            </div>
                        </div>
                    </div>
                    <hr style={{ width: "85%", marginLeft: "7%", marginRight: "7%" }} />
                    <div className="container" >

                        <div className='row' >

                            {/* <Row cols={4} md={4}     className="g-4" loop> */}
                            {allLiveAstrologers && allLiveAstrologers.length > 0 ? (
                                allLiveAstrologers.map((item, index) => (
                                    <Col sm={3} key={index}>
                                        <div className="col-lg-4  col-12"  >
                                            <div className="as_sign_box ">
                                                <div className="row">
                                                    <div className="col-md-6 col-6" style={{ padding: "10px" }}>
                                                        <img src="https://aws.astrotalk.com/consultant_pic/p-10473.jpg" />


                                                    </div>

                                                    <div className="col-md-6 col-6" style={{ padding: "10px" }}>
                                                        <h5 style={{ fontSize: "15px" }}> {item.name}</h5>


                                                        <p>{item.ratings}</p>
                                                        <p> Exp: 5 Years </p>
                                                        <p>Rs {item.per_min_call} /-</p>
                                                        {/* <b><h6 style={{color:"#c10101"}}> Free</h6> </b> */}

                                                        <br /><br />

                                                        <Button variant="primary" onClick={handleShow} style={{
                                                            color: "#28ba51",
                                                            backgroundColor: "#fff",
                                                            border: "1px solid"
                                                        }}>
                                                            Chat Now
                                                        </Button>

                                                        <Modal show={show} onHide={false}>
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>Login</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                <Form>

                                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                        <Form.Label>Phone Number</Form.Label>
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="phone number"
                                                                            autoFocus
                                                                            name="phone"
                                                                            value={userMobile.phone}
                                                                            onChange={e => onTextFieldChange(e)}
                                                                        />


                                                                    </Form.Group>

                                                                    {
                                                                        !isShown &&
                                                                        (
                                                                            <button type="button" onClick={() => {
                                                                                onFormSubmit();
                                                                                handleClick();
                                                                                //   handleClick();
                                                                            }}  >Continue</button>
                                                                        )
                                                                    }


                                                                    {isShown && (
                                                                        <div>





                                                                            <Form.Group
                                                                                className="mb-3"
                                                                                controlId="exampleForm.ControlTextarea1"
                                                                            // type="hidden"
                                                                            // onChange={(e) => { setValue(e.target.value) }}
                                                                            //   onClick={(e) => setShowed(true)}
                                                                            >
                                                                                <Form.Label>Otp</Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    placeholder="phone number"

                                                                                    name="user_pass"
                                                                                    value={userOtp.user_pass}
                                                                                    onChange={e => onTextFieldChangeotp(e)}

                                                                                />


                                                                            </Form.Group>
                                                                            <button type="button" onClick={e => onFormSubmitotp(e)} >Login</button>
                                                                        </div>

                                                                    )}

                                                                </Form>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button variant="secondary" onClick={handleClose}>
                                                                    Close
                                                                </Button>
                                                                <Button variant="primary" onClick={handleClose}>
                                                                    Save Changes
                                                                </Button>
                                                            </Modal.Footer>
                                                        </Modal>



                                                    </div>


                                                </div>
                                            </div>
                                        </div>

                                    </Col>


                                ))
                            ) :

                                null
                            }


                            {/* </Row> */}

                            {/* </div>          */}


                            {/* <a             className="carousel-control-prev" href="#myCarousel" data-slide="prev">
       <i             className="fa fa-angle-left"></i>
   </a>
   <a             className="carousel-control-next" href="#myCarousel" data-slide="next">
       <i             className="fa fa-angle-right"></i>
   </a> */}






                        </div>
                    </div>
                </section>






                <div className='fea'>

                    <div className='row'>

                        <h1 className="as_heading" style={{ paddingBottom: "2%", paddingLeft: "10%", paddingTop: "2%" }}>Call To Astrologers </h1>
                        <p style={{ paddingLeft: "10%", paddingRight: "10%", textAlign: "justify" }}>Why Google 'astrologer near me' when astrologers can come right to you and guide you through all your major life problems? And with Astrotalk, we have made this possible for you. Astrotalk's Live astrologer feature brings you the luxury of talking with astrologers live and asking them questions for free and that too without any hidden cost involved. Astrotalk Live is a unique feature, which is another innovation in the field of online astrology that makes astrology consultation effortless, entertaining and simple for both - the astrologers and the users. The feature currently is solely available on Astrotalk and is an innovation you will enjoy. So, hop on!!

                            Astrotalk is currently India's largest online astrology platform, with over 5000 astrologers on board. Known as one of the most authentic and genuine platforms to talk or chat with astrologers in India, Astrotalk deals in numerous astrology components, including Vedic astrology, Palmistry, Panchang, Lal Kitab astrology and much more. At Astrotalk, we got expert astrologers in all these segments. All these astrology elements have their own significance in the world of astrology and can help you in knowing various things about your life's past, present and future. Through all these components, a person can know about various aspects of his or her life, such as marriage, finance, love, education and much more.

                            The sole motive of Astrology online through chat, talk or live feature is to help people save time, money, and pain of finding astrologers for themselves in the hustle and bustle of the city. Besides, to ensure credibility, Astrotalk has, over the years, worked extensively to add value to customer service. And a good chunk of credit for this goes to the astrologers who work for 100% customer satisfaction using their knowledge to deliver impeccable astrology consultancy.



                        </p>


                        <section className="as_whychoose_wrapper" style={{ cursor: "pointer" }}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 text-center">
                                        <h1 className="as_heading">Want to know more about kundli</h1>

                                        <p className="as_font14 as_padderTop20 as_padderBottom50">Our professional and experienced Astrologers study the movements of the planets, sun, moon, and <br />stars and predict as to how these movements are influencing and/or can have an influence on people's lives.</p>
                                    </div>
                                    <div className="col-lg-12">
                                        <ul className="as_choose_ul">



                                            {/* <li style={{ paddingLeft: "10%" }}>

                                                <a href="/"> <button     className="as_btn" href="/kundlimatching" style={{ marginLeft: "50%" }}>Click Here </button></a>

                                            </li>
                                            <li style={{ paddingLeft: "25%" }}>

                                                <a href="">   <button     className="as_btn" style={{ marginLeft: "50%", width: "20%" }}>Chat With Astrologer </button></a>

                                            </li>
                                            <li style={{ paddingLeft: "30%" }}>

                                                <a href="">    <button     className="as_btn" style={{ marginLeft: "50%" }}>Talk To Astrologer </button></a>

                                            </li> */}

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>




                        <h4 style={{ paddingLeft: "10%", paddingTop: "3%", paddingBottom: "2%" }}>Daily Kundli Live Q&A </h4>

                        <h5 style={{ paddingLeft: "10%" }}>
                            Q 1. What is Daily Kundli Live?
                        </h5>
                        <p style={{ paddingLeft: "10%", paddingRight: "10%", textAlign: "justify" }}>
                            Daily kundli Live is a new and innovative way to talk to an astrologer through live sessions. On Astrotalk live, you can connect with an astrologer face-to-face and can ask them questions.
                        </p>

                        < h5 style={{ paddingLeft: "10%" }}>
                            Q 2. Is Astrotalk Live free?
                        </h5>
                        <p style={{ paddingLeft: "10%", paddingRight: "10%", textAlign: "justify" }}>

                            Astrology consultation on Astrotalk live is absolutely free. You don't have to spend even a penny on Astrotalk live and can ask astrologers questions for FREE. However, if you wish, you can make donations to the astrologers.
                        </p>

                        < h5 style={{ paddingLeft: "10%" }}>
                            Q 3. From where can I access live astrologers?
                        </h5>
                        <p style={{ paddingLeft: "10%", paddingRight: "10%", textAlign: "justify" }}>
                            You can either connect with live astrologers on the DailyKundli app or through our website.
                        </p>
                        < h5 style={{ paddingLeft: "10%" }}>
                            Q 3. What questions can I ask astrologers on Astrotalk live?
                        </h5>
                        <p style={{ paddingLeft: "10%", paddingRight: "10%", textAlign: "justify" }}>
                            Daily kundli has a pool of Astrologers versed in Vedic astrology, Vastu, Numerology, Tarot card reading, Panchang, and more. You can ask them questions about your education, love, health, marriage and so on. We recommend asking YES and NO questions to increase your chances of getting an answer.
                        </p>

                        < h5 style={{ paddingLeft: "10%" }}>
                            Q 3. Is Daily Kundli live safe?
                        </h5>
                        <p style={{ paddingLeft: "10%", paddingRight: "10%", textAlign: "justify" }}>
                            Daily Kundli Live is a 100% safe platform to use any time of the day, both financially and interaction wise. Daily kundli adopts the best practices to ensure not only a great user experience but also user safety, be it on the website or app. User privacy is the top priority for us, and hence we adopt the best encryption methodologies to protect transactions and any personal information that you share with us or the astrologer.
                        </p>



                    </div>
                    <div className='row' style={{ paddingBottom: "5%" }}></div>




                </div>


















            </div>








        </>



    )
}

export default Talktoastrologer