import React, { useState } from 'react'
// import {style} from '../Assets/style'
import Logo from '../Asoto-02 copy.png';
import { Link, } from 'react-router-dom';
// import Freekundali from '../components/freekundali';


import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';


function Header() {



  // let navigate = useNavigate() 


  let headers = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isShown, setIsShown] = useState(false);

  const [userMobile, setUserMobile] = useState({
    phone: "",


  })
  const [allLiveAstrologers, setAllLiveAstrologer] = useState([]);


  const [userOtp, setUserOtp] = useState({

    user_pass: "",
    phone: "",
  })
  const [otpBlank, setotpBlank] = useState({


  })

  const [alertMessage, setAlertMessage] = useState("");




  const handleClick = () => {
    // 👇️ toggle shown state
    setIsShown(current => !current);

  }




  function onFormSubmit() {

    try {
      axios.post(`https://backend.dailykundli.com/api/login_with_phone`, userMobile)
        .then((res) => {
          const LoginData = res.data;
          console.log(LoginData)
          // alert(res.data.phone);

          setUserOtp({
            ...userOtp,

            phone: res.data.phone
          })

        });
    } catch (error) {
      console.log(error);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }


  function onFormSubmitotp() {

    try {
      axios.post(`https://backend.dailykundli.com/api/login_with_otp`, userOtp)
        .then((res) => {
          const LoginData = res.data;
          console.log(LoginData);

          if (LoginData == "Invalid") {
            console.log("inside if block");
            alert("Invalid Login Details !!! Please Enter the correct Otp")
            setUserOtp({
              ...userOtp,

              user_pass: "",


            }

            )


            // window.scrollTo({ top: 0, behavior: 'smooth' });
          } else {




            //   window.location.assign('http://app.dailykundli.com/api/customer/astrologer_live_list?chat=1')
            window.location.assign("https://app.dailykundli.com/web/" + userMobile.phone + "/" + userOtp.user_pass);
          }
        });



    } catch (error) {
      console.log(error);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }



  }



  async function onTextFieldChange(e) {
    setUserMobile({
      ...userMobile,

      [e.target.name]: e.target.value
    })
  }
  async function onTextFieldChangeotp(e) {
    setUserOtp({
      ...userOtp,
      [e.target.name]: e.target.value
    })
  }

  return (
    <section style={{ boxShadow: "-1px 1px 8px 0px #616161", position: "fixed", width: "100%", background: "white", zIndex: "999" }}>
      <div className="row">
        <Navbar bg="" expand="lg"  >
          <Container style={{}}>
            <div className='col-lg-7 col-md-3 col-sm-4'>
              <Navbar.Brand href="/">
                <img src={Logo} style={{ height: "67px" }} />
              </Navbar.Brand>
            </div>
            <div class="col-lg-5 col-md-7 col-sm-7 ">
              <div className="as_right_info" style={{ justifyContent: "flex-end" }}>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto" style={{ color: "black !important" }}>
                    <Nav.Link href="#"   >
                      <button className="as_btn " style={{ marginTop: "0px !important" }}>
                        <i class="fa fa-phone" style={{ color: "white", fontSize: "17px", padding: "3px" }}></i>
                        <span >Talk To Astrologer</span>
                      </button>
                    </Nav.Link>
                    <Nav.Link href="#">
                      <button className="as_btn " style={{ marginTop: "0px !important" }}>
                        <i class="fa fa-phone" style={{ color: "white", fontSize: "17px" }}></i>
                        <span>Chat With Astrologer</span>
                      </button >
                    </Nav.Link>
                    {/* <select class="form-control colorblack table-borderless languagedropdown d-none d-lg-block" id="language" name="language">
                          <option value="en" selected="">ENG</option>
                          <option value="hi">हिन्दी</option>
                        </select> */}

                    <Nav.Link> 
                      <select name="language" id="cars" style={{ borderRadius: "2.25rem", margin: "14%" }}>
                      <option value="volvo">Hindi</option>
                      <option value="saab">Eng</option>
                    </select>
                    </Nav.Link>
                    <div className='' style={{ paddingLeft: "0px" }}>
                      <a href="#" style={{ fontSize: "18px" }}>
                        <i class="fa fa-user-plus " aria-hidden="true" onClick={handleShow} style={{ margin: "17%", fontSize: "20px", width: "100px" }}> Sign In</i>  </a>
                      {/* <a className="btn btn-primary" onClick={handleShow} style={{
                  backgroundColor: "transparent", border: "1px solid rgb(231 96 31)", color: "rgb(231 96 31)",
                }}> <i className="fa fa-user" aria-hidden="true"> Login </i> </a> */}
                      <Modal show={show} style={{ backgroundColor: "rgb(3 29 46 / 12%)" }} onHide={handleClose}>
                        <Modal.Header style={{
                          textAlign: "center",
                          fontWeight: "600",
                          color: "#313131",
                          margin: "0",
                          // padding: "12px 0",
                          borderBottom: "1px solid #ccc",
                          fontSize: "18px",
                          borderRadius: "10px 10px 0 0", width: "100%", height: "10vh"
                        }} closeButton>
                          <Modal.Title style={{ color: "white", textAlign: "center", paddingLeft: "30%" }} >Continue With Phone</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                          <p style={{ textAlign: "center", color: "black" }}>You will receive a 6 digit code
                            for verification</p>
                          <Form style={{ paddingTop: "2%", color: "black" }}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                              <Form.Label> Enter Your Phone Number</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="phone number"
                                autoFocus
                                name="phone"
                                value={userMobile.phone}
                                onChange={e => onTextFieldChange(e)}
                              />


                            </Form.Group>

                            {
                              !isShown &&
                              (
                                <Button type="button"
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "500",
                                    color: "#313131",
                                    margin: "0",
                                    padding: "8px 2px",
                                    borderBottom: "1px solid #ccc",
                                    fontSize: "18px",
                                    backgroundColor: "white",
                                    color: "#e7601f",
                                    borderColor: "#e7601f",
                                    width: "100%",

                                    // borderRadius: "10px 10px 0 0", width: "50%",height:"10vh" ,marginLeft:"20%"
                                  }} onClick={() => {
                                    onFormSubmit();
                                    handleClick();
                                    //   handleClick();
                                  }}  >GET OTP</Button>
                              )
                            }


                            {isShown && (
                              <div>


                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"

                                >
                                  <Form.Label>Enter Your OTP</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="otp"

                                    name="user_pass"
                                    value={userOtp.user_pass}
                                    onChange={e => onTextFieldChangeotp(e)}


                                  />

                                </Form.Group>
                                {alertMessage}

                                <a className="btn btn-primary" onClick={e => onFormSubmitotp(e)} style={{ backgroundColor: "transparent", border: "1px solid rgb(231, 96, 31)", color: "rgb(231, 96, 31)", width: "100%" }}>
                                  <i className="fa fa-user" aria-hidden="true"> Login </i> </a>
                              </div>

                            )}
                            <p style={{ marginTop: "4%" }}>By Signing up, you agree to our  <span style={{ color: "blue" }}>Terms of Use </span> and <span style={{ color: "blue" }}> Privacy Policy </span></p>


                          </Form>
                        </Modal.Body>
                        <Modal.Footer>

                        </Modal.Footer>
                      </Modal>

                    </div>
                    &nbsp; &nbsp;
                    {/* 
            <a href="#" style={{fontSize: "18px"}}>
               <i class="fa fa-user-plus " aria-hidden="true" style={{margin:"17%",fontSize: "20px",width: "100px"}}> Sign In</i>  </a> */}
                  </Nav>
                </Navbar.Collapse>
              </div>
            </div>
          </Container>
        </Navbar>
      </div>
    </section>
    // <section style={{ boxShadow: "-1px 1px 8px 0px #616161",position:" fixed",width: "100%",background: "white",zIndex: "999"}}>
    //     <div class="container">
    //       <div class="row">
    //         <div class="col-lg-3 col-md-3 col-sm-4 ">
    //           <div class="as_logo">
    //             <a href=" assets/images/logo.png">
    //               <img src="assets/images/lo.png"/>
    //             </a>
    //           </div>
    //         </div>
    //         <div class="col-lg-9 col-md-7 col-sm-7 ">
    //           <div class="as_right_info">
    //             <div class="as_menu_wrapper">
    //               <span class="as_toggle">
    //                 <img src=" https://hucpl.com/Astro_Aruna/assets/images/svg/menu.svg" alt="" />
    //               </span>
    //               <div class="as_menu">
    //                 <ul>
    //                   <li>
    //                     <a href="#" class="as_btn mart " style={{fontSize: "17px",color:"white"}}>
    //                       <i class="fa fa-phone  " style={{color: "white",fontSize: "17px",padding: "3px"}}></i>
    //                       <span> Talk To Astrologer </span>
    //                     </a>
    //                   </li>
    //                   <li>
    //                     <a href="#" style={{color: "white",fontSize: "17px"}} class="as_btn mart">
    //                       <i class="fa fa-comments-o " aria-hidden="true" style={{color: "white",fontSize: "17px",padding: "3px"}}></i>
    //                       <span>Chat With Astrologer </span>
    //                     </a>
    //                   </li>

    //                     <select class="form-control colorblack table-borderless languagedropdown d-none d-lg-block" id="language" name="language">
    //                       <option value="en" selected="">ENG</option>
    //                       <option value="hi">हिन्दी</option>
    //                     </select>
    //                     <li>
    //                     <a class="d-block d-lg-none mr-2" data-toggle="modal" data-target="#LanguageModalPopup">
    //                       <img src="https://cdn.anytimeastro.com/dashaspeaks/web/content/anytimeastro/images/language.svg" alt="chat" style ={{width:"26" ,height:"26"}}/>
    //                     </a>
    //                   </li>
    //                   <li>
    //                     <a href="register.html" style={{fontSize: "18px"}}>
    //                       <i class="fa fa-user-plus" aria-hidden="true" style={{marginTop: "22px !important",fontSize: "20px"}}></i> Sign In </a>
    //                   </li>
    //               </ul></div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </section>

  )
}

function Freekundali() {
  const nameUrl = window.location.href
  return <h2>{nameUrl}</h2>
}

export default Header