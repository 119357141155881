import React from 'react'
import { Link, } from 'react-router-dom';
import horoscope1 from '../../horoscope-01.jpg';

function Capricorn() {
    return (
        <div>


            <section className="as_breadcrum_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center" style={{ textAlign: "center" }}>
                            <h1 style={{ paddingTop: "5px" }}>  CAPRICORN MONTHLY HOROSCOPE 2023</h1>

                            <ul className="breadcrumb">
                                <li><a href="/">Home</a></li>

                            </ul>
                        </div>
                    </div>
                </div>
            </section>


            <div className='row'>

                <div className='col-8'>

                    <h3 style={{ textAlign: "center", paddingTop: "2%" }}>Capricorn Monthly horoscope</h3>


                    <p style={{ textAlign: "justify", padding: "2%", paddingLeft: "10%", paddingRight: "10%" }}>
                        A new year and new ways! Here we are in the New year 2023 with a new journey. From the beginning, Venus will bestow its grace when it moves in the Aquarius zodiac sign on January 3. Your love and relationship sector will bloom as per the Aries monthly horoscope. Natives might see some ups and downs in their life, but nothing serious would affect their bond with your person. Moreover, the Sun-Mercury conjunction in the Capricorn sign on January 7 will improve your professional life. Especially, people looking for new job opportunities will seek a fine time finding the one that suits their skill set.
                        Ahead, around the second half of the month, when Mercury will be directly in the Capricorn sign on January 18, Aries natives might face minor troubles regarding their finances. It could be a mere possibility that you may lose some if you are not careful. Therefore, the January horoscope 2023 suggests you be careful regarding the same. And last but not least, regarding your health sector, the New Moon in the Aquarius sign on January 22 could be highly influential. You might see a little downfall in your well-being, but nothing would cause casualties.

                        <br />


                    </p>
                    <p style={{ textAlign: "justify", padding: "2%", paddingLeft: "10%", paddingRight: "10%" }}>
                        <h5> Love and Relationship</h5>
                        Couples shall be great in their relationship. Since there are good prospects of marriage, Aries men and women can prepare and put their best efforts to give it the right direction. Furthermore, The Aries monthly love horoscope foretells that singles with this zodiac sign might face certain troubles. The same could keep you occupied. However, if you wish to marry, considering options for marriage will be possible. People going through a rough time in their relationship and natives expecting a reconciliation with their partners must keep calm and wait for a little bit more time.
                        On the other hand, newly wedded couples with the Aries sign shall have a serene time. They will enjoy being around each other, and a little vacation could be there for such a couple as well. But, stay a little alert around the relatives as they might cause issues in your married life unusually.

                    </p>

                    <p style={{ textAlign: "justify", padding: "2%", paddingLeft: "10%", paddingRight: "10%" }}>
                        <h5> Money and Finance</h5>
                        Couples shall be great in their relationship. Since there are good prospects of marriage, Aries men and women can prepare and put their best efforts to give it the right direction. Furthermore, The Aries monthly love horoscope foretells that singles with this zodiac sign might face certain troubles. The same could keep you occupied. However, if you wish to marry, considering options for marriage will be possible. People going through a rough time in their relationship and natives expecting a reconciliation with their partners must keep calm and wait for a little bit more time.
                        On the other hand, newly wedded couples with the Aries sign shall have a serene time. They will enjoy being around each other, and a little vacation could be there for such a couple as well. But, stay a little alert around the relatives as they might cause issues in your married life unusually.

                    </p>

                    <p style={{ textAlign: "justify", padding: "2%", paddingLeft: "10%", paddingRight: "10%" }}>
                        <h5> Love and Relationship</h5>
                        Couples shall be great in their relationship. Since there are good prospects of marriage, Aries men and women can prepare and put their best efforts to give it the right direction. Furthermore, The Aries monthly love horoscope foretells that singles with this zodiac sign might face certain troubles. The same could keep you occupied. However, if you wish to marry, considering options for marriage will be possible. People going through a rough time in their relationship and natives expecting a reconciliation with their partners must keep calm and wait for a little bit more time.
                        On the other hand, newly wedded couples with the Aries sign shall have a serene time. They will enjoy being around each other, and a little vacation could be there for such a couple as well. But, stay a little alert around the relatives as they might cause issues in your married life unusually.

                    </p>

                </div>

                <div className='col-4'>
                    <div className="as_shop_sidebar" style={{ paddingRight: "10%" }}>
                        <div className="as_widget as_search_widget" style={{ paddingTop: "2%" }}>
                            <select id="cars" style={{
                                width: "80%",
                                padding: " 8px 5px",
                                fontSize: "18px",
                                borderRadius: "5px",
                                border: "1px solid #ccc",
                                color: "#919191",
                                cursor: "pointer"
                            }}>
                                <option value="aries">Aries</option>
                                <option value="taurus">Taurus</option>
                                <option value="gemini">Gemini</option>
                                <option value="cancer" selected>Cancer</option>
                                <option value="leo" selected>Leo</option>
                                <option value="virgo" selected>Virgo</option>
                                <option value="libra" selected>Libra</option>
                                <option value="scorpio" selected>Scorpio</option>
                                <option value="sagittarius" selected>Sagittarius</option>
                                <option value="capricorn" selected>Capricorn</option>
                                <option value="aquarius" selected>Aquarius</option>
                                <option value="pisces" selected>Pisces</option>
                            </select>
                            {/* <input type="text" name=""     className="form-control" id="" placeholder="Search" /> */}
                            {/* <span><img src="assets/images/svg/search.svg" alt="" /></span> */}
                        </div>
                    </div>


                    <h5 style={{ paddingTop: "4%" }}> Horoscopes: </h5>
                    <h5 style={{ paddingTop: "4%" }}>
                        <Link to='/horoscope/todayshoroscope'>Today's Horoscope </Link></h5>
                    <br />
                    <h5>
                        <Link to='/horoscope/tomorrowhoroscope'>Tomorrow's Horoscope </Link>
                    </h5>
                    <br />
                    <h5>
                        <Link to='/horoscope/yesterdayhoroscope'>Yesterday's Horoscope </Link>
                    </h5>
                    <br />
                    <h5>
                        <Link to='/horoscope/weeklyhoroscope'>  Weekly Horoscope </Link>
                    </h5>
                    <br />
                    <h5>
                        <Link to='/horoscope/monthlyhoroscope'>  Monthly Horoscope </Link>
                    </h5>
                    <br />
                    <h5>
                        <Link to='/horoscope/yearlyhoroscope'>  Yearly Horoscope </Link>
                    </h5>
                    <br />

                </div>




            </div>


            <div className='row'>
                <div className='container-fluid'>
                    <div className="col-lg-12 col-md-12 text-center" style={{ backgroundColor: "#f4a336", paddingTop: "3%" }}>
                        <h1 className="as_heading">ARIES COMPATIBILITY WITH OTHER SIGNS</h1>
                        <div className='row' style={{ paddingLeft: "10%", paddingRight: "10%", paddingTop: "3%" }}>
                            <div className='col-2'>
                                <img src={horoscope1} style={{ height: "50", width: "50", border: "2px solid #fff" }}></img>
                                <br />
                                <Link to="/compatibility/aries-aries" style={{ textDecoration: "none" }}>
                                    <button className="as_btn" style={{ marginTop: "3%" }} placeholder="Aries & Aries"> Aries & Aries</button>
                                </Link>
                            </div>

                            <div className='col-2'>
                                <img src={horoscope1} style={{ height: "50", width: "50", border: "2px solid #fff" }}></img>
                                <br />
                                <Link to="/compatibility/aries-taurus" style={{ textDecoration: "none" }}>
                                    <button className="as_btn" style={{ marginTop: "3%" }} placeholder="Aries & Aries"> Aries & Taurus</button>
                                </Link>
                            </div>
                            <div className='col-2'>
                                <img src={horoscope1} style={{ height: "50", width: "50", border: "2px solid #fff" }}></img>
                                <br />

                                <Link to="/compatibility/aries-gemini" style={{ textDecoration: "none" }}>
                                    <button className="as_btn" style={{ marginTop: "3%" }} href="/freekundli" placeholder="Aries & Aries"> Aries & Gemini</button>
                                </Link>
                            </div>
                            <div className='col-2'>
                                <img src={horoscope1} style={{ height: "50", width: "50", border: "2px solid #fff" }}></img>
                                <br />
                                <Link to="/compatibility/aries-cancer" style={{ textDecoration: "none" }}>
                                    <button className="as_btn" style={{ marginTop: "3%" }} href="/freekundli" placeholder="Aries & Aries"> Aries & Cancer</button>
                                </Link>
                            </div>
                            <div className='col-2'>
                                <img src={horoscope1} style={{ height: "50", width: "50", border: "2px solid #fff" }}></img>
                                <br />
                                <Link to="/compatibility/aries-leo" style={{ textDecoration: "none" }}>
                                    <button className="as_btn" style={{ marginTop: "3%" }} href="/freekundli" placeholder="Aries & Aries"> Aries & Leo</button>
                                </Link>
                            </div>
                            <div className='col-2'>
                                <img src={horoscope1} style={{ height: "50", width: "50", border: "2px solid #fff" }}></img>
                                <br />
                                <Link to="/compatibility/aries-virgo" style={{ textDecoration: "none" }}>
                                    <button className="as_btn" style={{ marginTop: "3%" }} href="/freekundli" placeholder="Aries & Aries"> Aries & Virgo</button>
                                </Link>
                            </div>

                            <div className='row' style={{ paddingTop: "2%", paddingBottom: "3%" }}>

                                <div className='col-2'>
                                    <img src={horoscope1} style={{ height: "50", width: "50", border: "2px solid #fff" }}></img>
                                    <br />
                                    <Link to="/compatibility/aries-libra" style={{ textDecoration: "none" }}>
                                        <button className="as_btn" style={{ marginTop: "3%" }} href="/freekundli" placeholder="Aries & Aries"> Aries & Libra</button>
                                    </Link>
                                </div>
                                <div className='col-2'>
                                    <img src={horoscope1} style={{ height: "50", width: "50", border: "2px solid #fff" }}></img>
                                    <br />
                                    <Link to="/compatibility/aries-scorpio" style={{ textDecoration: "none" }}>
                                        <button className="as_btn" style={{ marginTop: "3%" }} placeholder="Aries & Aries"> Aries & Scorpio</button>
                                    </Link>
                                </div>
                                <div className='col-2'>
                                    <img src={horoscope1} style={{ height: "50", width: "50", border: "2px solid #fff" }}></img>
                                    <br />
                                    <Link to="/compatibility/aries-sagittarius" style={{ textDecoration: "none" }}>
                                        <button className="as_btn" style={{ marginTop: "3%" }} placeholder="Aries & Aries"> Aries & Sagittarius</button>
                                    </Link>
                                </div>
                                <div className='col-2'>
                                    <img src={horoscope1} style={{ height: "50", width: "50", border: "2px solid #fff" }}></img>
                                    <br />
                                    <Link to="/compatibility/aries-capricorn" style={{ textDecoration: "none" }}>
                                        <button className="as_btn" style={{ marginTop: "3%" }} placeholder="Aries & Aries"> Aries & Capricorn</button>
                                    </Link>
                                </div>
                                <div className='col-2'>
                                    <img src={horoscope1} style={{ height: "50", width: "50", border: "2px solid #fff" }}></img>
                                    <br />
                                    <Link to="/compatibility/aries-aquarius" style={{ textDecoration: "none" }}>
                                        <button className="as_btn" style={{ marginTop: "3%" }} placeholder="Aries & Aries"> Aries & Aquarius</button>
                                    </Link>
                                </div>
                                <div className='col-2'>
                                    <img src={horoscope1} style={{ height: "50", width: "50", border: "2px solid #fff" }}></img>
                                    <br />
                                    <Link to="/compatibility/aries-pisces" style={{ textDecoration: "none" }}>
                                        <button className="as_btn" style={{ marginTop: "3%" }} placeholder="Aries & Aries"> Aries & Pisces</button>
                                    </Link>
                                </div>



                            </div>

                        </div>



                    </div>
                </div>

            </div>













            <section className="as_whychoose_wrapper" style={{ cursor: "pointer" }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 text-center">
                            <h1 className="as_heading">Want to know more about kundli</h1>
                            <p className="as_font14 as_padderTop20 as_padderBottom50">
                                Our professional and experienced Astrologers study the movements of the planets, sun, moon, and <br />stars and predict as to how these movements are influencing and/or can have an influence on people's lives.</p></div><div className="col-lg-12">
                            <ul className="as_choose_ul">
                                <li style={{ paddingLeft: "10%" }}>
                                    <a href="/">
                                        <button className="as_btn" href="/kundlimatching" style={{ marginLeft: "50%" }}>Click Here </button></a></li>
                                <li style={{ paddingLeft: "25%" }}><a href="">
                                    <button className="as_btn" style={{ marginLeft: "50%", width: "20%" }}>Chat With Astrologer </button></a></li>
                                <li style={{ paddingLeft: "30%" }}>
                                    <a href="" ><button className="as_btn" style={{ marginLeft: "50" }}>Talk To Astrologer </button>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}

export default Capricorn