import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import {BrowserRouter,Routes,Route} from "react-router-dom";

import Freekundali from './components/freekundali';
import Kundalimatching from './components/kundalimatching';
import Header from './Layouts/header';
import Footer from './Layouts/footer';
import Liveastrologer from './components/liveastrologer';
import ShubhMuhrat from './components/shubhMuhrat';
import Planetarytransit from './components/planetarytransit';
import TodayPanchang from './components/TodayPanchang';
import Blog from './components/blog';
import Blogs from './components/blogs';
import Horoscope2023 from './components/horoscope2023';
import Todayshoroscope from './components/todayshoroscope';
import Dailyhoroscope from './components/dailyhoroscope';
import Weeklyhoroscope from './components/weeklyhoroscope';
import Monthlyhoroscope from './components/Monthly/monthlyhoroscope';
import Yearlyhoroscope from './components/yearlyhoroscope';
import Register from './components/register';
import Login from './components/login';
import TalktoAstrologer from './components/talktoastrologer'
import Chatwithastrologer from './components/chatwithastrologer';
import Tomorrowhoroscope from './components/tomorrowhoroscope';
import Yesterdayhoroscope from './components/yesterdayhoroscope';
import AriesMonthlyhoroscope from './components/Monthly/aries';
import TaurusMonthlyhoroscope from './components/Monthly/taurus';
import GeminiMonthlyhoroscope from './components/Monthly/gemini';
import CancerMonthlyhoroscope from './components/Monthly/cancer';
import LeoMonthlyhoroscope from './components/Monthly/leo';
import SaggitariusMonthlyhoroscope from './components/Monthly/saggitarius';
import VirgoMonthlyhoroscope from './components/Monthly/virgo';
import AquariusMonthlyhoroscope from './components/Monthly/aquarius';
import LibraMonthlyhoroscope from './components/Monthly/libra';
import CapricornMonthlyhoroscope from './components/Monthly/capricorn';
import ScorpioMonthlyhoroscope from './components/Monthly/scorpio';
import PiscesMonthlyhoroscope from './components/Monthly/pisces';

import AriesWeeklyhoroscope from './components/Weekly/aries';
import TaurusWeeklyhoroscope from './components/Weekly/taurus';
import GeminiWeeklyhoroscope from './components/Weekly/gemini';
import CancerWeeklyhoroscope from './components/Weekly/cancer';
import LeoWeeklyhoroscope from './components/Weekly/leo';
import SaggitariusWeeklyhoroscope from './components/Weekly/sagittarius';
import VirgoWeeklyhoroscope from './components/Weekly/virgo';
import AquariusWeeklyhoroscope from './components/Weekly/aquarius';
import LibraWeeklyhoroscope from './components/Weekly/libra';
import CapricornWeeklyhoroscope from './components/Weekly/capricorn';
import ScorpioWeeklyhoroscope from './components/Weekly/scorpio';
import PiscesWeeklyhoroscope from './components/Weekly/pisces';

import AriesYearlyhoroscope from './components/Yearly/aries';
import TaurusYearlyhoroscope from './components/Yearly/taurus';
import GeminiYearlyhoroscope from './components/Yearly/gemini';
import CancerYearlyhoroscope from './components/Yearly/cancer';
import LeoYearlyhoroscope from './components/Yearly/leo';
import VirgoYearlyhoroscope from './components/Yearly/virgo';
import LibraYearlyhoroscope from './components/Yearly/libra';
import AquariusYearlyhoroscope from './components/Yearly/aquarius';
import CapricornYearlyhoroscope from './components/Yearly/capricorn';
import ScorpioYearlyhoroscope from './components/Yearly/scorpio';
import SaggitariusYearlyhoroscope from './components/Yearly/sagittarius';
import PiscesYearlyhoroscope from './components/Yearly/pisces';


import AriesTomorrowhoroscope from './components/Tomorrow/aries';
import TaurusTomorrowhoroscope from './components/Tomorrow/taurus';
import GeminiTomorrowhoroscope from './components/Tomorrow/gemini';
import CancerTomorrowhoroscope from './components/Tomorrow/cancer';
import LeoTomorrowhoroscope from './components/Tomorrow/leo';
import VirgoTomorrowhoroscope from './components/Tomorrow/virgo';
import LibraTomorrowhoroscope from './components/Tomorrow/libra';
import AquariusTomorrowhoroscope from './components/Tomorrow/aquarius';
import CapricornTomorrowhoroscope from './components/Tomorrow/capricorn';
import ScorpioTomorrowhoroscope from './components/Tomorrow/scorpio';
import SagittariusTomorrowhoroscope from './components/Tomorrow/sagittarius';
import PiscesTomorrowhoroscope from './components/Tomorrow/pisces';



import AriesCompatibility from './components/compatibility/aries';
import TaurusCompatibility from './components/compatibility/taurus';
import GeminiCompatibility from './components/compatibility/gemini';
import CancerCompatibility from './components/compatibility/cancer';
import LeoCompatibility from './components/compatibility/leo';
import VirgoCompatibility from './components/compatibility/virgo';
import LibraCompatibility from './components/compatibility/libra';
import ScorpioCompatibility from './components/compatibility/scorpio';
import SagittariusCompatibility from './components/compatibility/sagittarius';
import CapricornCompatibility from './components/compatibility/capricorn';
import AquariusCompatibility from './components/compatibility/aquarius';
import PiscesCompatibility from './components/compatibility/pisces';
import AnnaprashanMuhurat2023 from './components/AnnanprashanMuhurat2023';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Header />
  <Routes>
  <Route path="/" element={<App />} />
  <Route path="/freekundali" element={<Freekundali />} />
  <Route path="/kundalimatching" element={<Kundalimatching />} />
  <Route path="/liveastrologer" element={<Liveastrologer />} />
  <Route path="/chat-with-astrologer" element={<Chatwithastrologer />} />
  <Route path="/shubhmuhrat" element={<ShubhMuhrat />} />
  <Route path="/planetarytransit" element={<Planetarytransit />} />
  <Route path="/todaypanchang" element={<TodayPanchang />} />
  <Route path="/blog" element={<Blog />} />
  <Route path="/blogs" element ={<Blogs />} />
  <Route path="/horoscope/dailyhoroscope" element ={<Dailyhoroscope />} />
  <Route path ="/call-to-astrologer" element={<TalktoAstrologer />} />
  <Route path="/horoscope2023" element={<Horoscope2023 />} />
  <Route path="/horoscope/todayshoroscope" element={<Todayshoroscope />} />
  <Route path="/horoscope/weeklyhoroscope" element={<Weeklyhoroscope />} />
  <Route path="/horoscope/monthlyhoroscope" element={<Monthlyhoroscope />} />
  <Route path="/horoscope/tomorrowhoroscope" element={<Tomorrowhoroscope />} />
  <Route path="/horoscope/yesterdayhoroscope" element={<Yesterdayhoroscope />} />
  <Route path="/horoscope/yearlyhoroscope" element={<Yearlyhoroscope />} />
  <Route path="/horoscope/monthlyhoroscope/arieshoroscope" element={<AriesMonthlyhoroscope />} />
  <Route path="/horoscope/monthlyhoroscope/taurushoroscope" element={<TaurusMonthlyhoroscope />} />
  <Route path="/horoscope/monthlyhoroscope/geminihoroscope" element={<GeminiMonthlyhoroscope />} />
  <Route path="/horoscope/monthlyhoroscope/cancerhoroscope" element={<CancerMonthlyhoroscope />} />
  <Route path="/horoscope/monthlyhoroscope/leohoroscope" element={<LeoMonthlyhoroscope />} />
  <Route path="/horoscope/monthlyhoroscope/virgohoroscope" element={<VirgoMonthlyhoroscope />} />
  <Route path="/horoscope/monthlyhoroscope/librahoroscope" element={<LibraMonthlyhoroscope />} />
  <Route path="/horoscope/monthlyhoroscope/scorpiohoroscope" element={<ScorpioMonthlyhoroscope />} />
  <Route path="/horoscope/monthlyhoroscope/saggitariushoroscope" element={<SaggitariusMonthlyhoroscope />} />
  <Route path="/horoscope/monthlyhoroscope/capricornhoroscope" element={<CapricornMonthlyhoroscope />} />
  <Route path="/horoscope/monthlyhoroscope/aquariushoroscope" element={<AquariusMonthlyhoroscope />} />
  <Route path="/horoscope/monthlyhoroscope/pisceshoroscope" element={<PiscesMonthlyhoroscope />} />

  {/* Weekly Horoscope */}
  <Route path="/horoscope/weeklyhoroscope/arieshoroscope" element={<AriesWeeklyhoroscope />} />
  <Route path="/horoscope/weeklyhoroscope/taurushoroscope" element={<TaurusWeeklyhoroscope />} />
  <Route path="/horoscope/weeklyhoroscope/geminihoroscope" element={<GeminiWeeklyhoroscope />} />
  <Route path="/horoscope/weeklyhoroscope/cancerhoroscope" element={<CancerWeeklyhoroscope />} />
  <Route path="/horoscope/weeklyhoroscope/leohoroscope" element={<LeoWeeklyhoroscope />} />
  <Route path="/horoscope/weeklyhoroscope/virgohoroscope" element={<VirgoWeeklyhoroscope />} />
  <Route path="/horoscope/weeklyhoroscope/librahoroscope" element={<LibraWeeklyhoroscope />} />
  <Route path="/horoscope/weeklyhoroscope/scorpiohoroscope" element={<ScorpioWeeklyhoroscope />} />

  <Route path="/horoscope/weeklyhoroscope/saggitariushoroscope" element={< SaggitariusWeeklyhoroscope />} />
  <Route path="/horoscope/weeklyhoroscope/capricornhoroscope" element={<CapricornWeeklyhoroscope />} />
  <Route path="/horoscope/weeklyhoroscope/aquariushoroscope" element={<AquariusWeeklyhoroscope />} />
  <Route path="/horoscope/weeklyhoroscope/pisceshoroscope" element={<PiscesWeeklyhoroscope />} />
   
   {/* Yearly horoscope */}

   <Route path="/horoscope/yearlyhoroscope/arieshoroscope" element={<AriesYearlyhoroscope />} />
  <Route path="/horoscope/yearlyhoroscope/taurushoroscope" element={<TaurusYearlyhoroscope />} />
  <Route path="/horoscope/yearlyhoroscope/geminihoroscope" element={<GeminiYearlyhoroscope />} />
  <Route path="/horoscope/yearlyhoroscope/cancerhoroscope" element={<CancerYearlyhoroscope />} />
  <Route path="/horoscope/yearlyhoroscope/leohoroscope" element={<LeoYearlyhoroscope />} />
  <Route path="/horoscope/yearlyhoroscope/virgohoroscope" element={<VirgoYearlyhoroscope />} />
  <Route path="/horoscope/yearlyhoroscope/librahoroscope" element={<LibraYearlyhoroscope />} />
  <Route path="/horoscope/yearlyhoroscope/scorpiohoroscope" element={<ScorpioYearlyhoroscope />} />

  <Route path="/horoscope/yearlyhoroscope/saggitariushoroscope" element={< SaggitariusYearlyhoroscope />} />
  <Route path="/horoscope/yearlyhoroscope/capricornhoroscope" element={<CapricornYearlyhoroscope />} />
  <Route path="/horoscope/yearlyhoroscope/aquariushoroscope" element={<AquariusYearlyhoroscope />} />
  <Route path="/horoscope/yearlyhoroscope/pisceshoroscope" element={<PiscesYearlyhoroscope />} />
  
  

  {/* Tomorrow horoscope */}
  
  <Route path="/horoscope/tomorrowhoroscope/arieshoroscope" element={<AriesTomorrowhoroscope />} />
  <Route path="/horoscope/tomorrowhoroscope/taurushoroscope" element={<TaurusTomorrowhoroscope />} />
  <Route path="/horoscope/tomorrowhoroscope/geminihoroscope" element={<GeminiTomorrowhoroscope />} />
  <Route path="/horoscope/tomorrowhoroscope/cancerhoroscope" element={<CancerTomorrowhoroscope />} />
  <Route path="/horoscope/tomorrowhoroscope/leohoroscope" element={<LeoTomorrowhoroscope />} />
  <Route path="/horoscope/tomorrowhoroscope/virgohoroscope" element={<VirgoTomorrowhoroscope />} />
  <Route path="/horoscope/tomorrowhoroscope/librahoroscope" element={<LibraTomorrowhoroscope />} />
  <Route path="/horoscope/tomorrowhoroscope/scorpiohoroscope" element={<ScorpioTomorrowhoroscope />} />
  <Route path="/horoscope/tomorrowhoroscope/sagittariushoroscope" element={<SagittariusTomorrowhoroscope />} />
  <Route path="/horoscope/tomorrowhoroscope/capricornhoroscope" element={<CapricornTomorrowhoroscope />} />
  <Route path="/horoscope/tomorrowhoroscope/Aquariushoroscope" element={<AquariusTomorrowhoroscope />} />
  <Route path="/horoscope/tomorrowhoroscope/pisceshoroscope" element={<PiscesTomorrowhoroscope />} />
  
  




  
   {/* Today Compatibility */}
   <Route path="/compatibility/aries-aries" element={<AriesCompatibility/>} />
   <Route path="/compatibility/aries-taurus" element={<TaurusCompatibility/>} />
   <Route path="/compatibility/aries-gemini" element={<GeminiCompatibility/>} />
   <Route path="/compatibility/aries-leo" element={<LeoCompatibility/>} />
   <Route path="/compatibility/aries-cancer" element={<CancerCompatibility/>} />
   <Route path="/compatibility/aries-virgo" element={<VirgoCompatibility/>} />
   <Route path="/compatibility/aries-libra" element={<LibraCompatibility/>} />
   <Route path="/compatibility/aries-scorpio" element={<ScorpioCompatibility/>} />
   <Route path="/compatibility/aries-sagittarius" element={<SagittariusCompatibility/>} />
   <Route path="/compatibility/aries-capricorn" element={<CapricornCompatibility/>} />
   <Route path="/compatibility/aries-aquarius" element={<AquariusCompatibility/>} />
   <Route path="/compatibility/aries-pisces" element={<PiscesCompatibility/>} />
  
  

<Route path="/Annaprashan-muhrat-2023" element= {<AnnaprashanMuhurat2023 />} />

  <Route path="/register" element={<Register />} />

  <Route path="/login" element={<Login />} />



  {/* <Route path="/login" element={<Login />} />
  <Route path="/superadmin/*" element={<SuperAdminRoutes />} />
  <Route path="/admin/*" element={<AdminRoutes />} />
  <Route path="/astrologer/*" element={<AstrologerRoutes />} /> */}
  </Routes>
  <Footer />
</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
