import React, { useState } from "react";
// import "../Assets/style.css";

const CompatibleForm = () => {




    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: ""
    });

    const updateFormData = event =>
        setFormData({
            ...formData,
            // [event.target.name]: event.target.value
        });

    const { firstName, lastName, email, password } = formData;

    return (
        <form     class="as_appointment_form">

            <div     class="form-group" >

                <div     class="row" style={{ marginTop: "5%", marginBottom: "5%" }} >
                    <h5 style={{ textAlign: "center", paddingTop: "4%" }}>  ARE YOU COMPATIBLE ?


                    </h5>
                    <span style={{ fontSize: "14px" }}>Choose your and your partner's zodiac sign to check compatibility</span>
                    <div     class="col-6" style={{ marginTop: "5%" }}>
                        <p style={{ fontSize: "14px" }}>Your Sign</p>
                        <select     class="form-control-dropdown-toggle" name="fee_payment_mode" data-toggle="dropdown" placeholder="Select Gender" >
                            <option value="aries">Aries</option>
                            <option value="taurus">Taurus</option>
                            <option value="gemini">Gemini</option>
                            <option value="cancer">Cancer</option>
                            <option value="leo">Leo</option>
                            <option value="virgo">Virgo</option>

                            <option value="scorpio">Scorpio</option>
                            <option value="sagittarius">Sagittarius</option>
                            <option value="capricorn">Capricorn</option>
                            <option value="aquarius">Aquarius</option>
                            <option value="pisces">Pisces</option>
                        </select>
                        {/* <option value={leadData.fee_payment_mode} >--Select--</option>
                                                {
                                                    leadData.fee_payment_mode == "Online" ? 
                                                    <option selected value="Online">Online</option>
                                                    :
                                                    <option value="Online">Online</option>
                                                } */}
                        { /*
                                                    leadData.fee_payment_mode == "Cash" ? 
                                                    <option selected value="Cash">Cash</option>
                                                    :
                                                    <option value="Cash">Cash</option>
                                                */ }
                        { /*
                                                    leadData.fee_payment_mode == "Swift" ? 
                                                    <option selected value="Swift">Swift</option>
                                                    :
                                                    <option value="Swift">Swift</option>
                                                */ }
                        {/* </select> */}
                        {/* <input type="text" name="" value="" placeholder="Gender"             class="form-control" /> */}
                    </div>
                    <div     class="col-6" style={{ marginTop: "5%" }}>
                        <p style={{ textAlign: "center", fontSize: "14px" }}>Your Partners Sign</p>
                        <select     class="form-control-dropdown-toggle" name="fee_payment_mode"  data-toggle="dropdown" placeholder="Select Gender" >
                            <option value="aries">Aries</option>
                            <option value="taurus">Taurus</option>
                            <option value="gemini">Gemini</option>
                            <option value="cancer">Cancer</option>
                            <option value="leo">Leo</option>
                            <option value="virgo">Virgo</option>

                            <option value="scorpio">Scorpio</option>
                            <option value="sagittarius">Sagittarius</option>
                            <option value="capricorn">Capricorn</option>
                            <option value="aquarius">Aquarius</option>
                            <option value="pisces">Pisces</option>
                        </select>
                    </div>
                </div>
            </div>
            <div     class="form-group">





            </div>

            <button     class="as_btn" style={{ marginBottom: "2%", marginLeft: "20%", marginBottom: "10%" }}>Generate Horoscope</button>
            <br />
        </form>



    );
};

export default CompatibleForm;
