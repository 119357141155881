import React from 'react';
import sun from '../sun.jpg';
import moon from '../moon.jpg';
import lagna from '../download.png';

function TodayPanchang() {
    return (
        <div>

            <section className="as_breadcrum_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center" style={{ textAlign: "center" }}>
                            <h1 style={{ paddingTop: "5px" }}>Today Panchang</h1>

                            <ul className="breadcrumb">
                                <li><a href="/">Home</a></li>

                            </ul>
                        </div>
                    </div>
                </div>
            </section>


            <div className='row'>

                <h3 style={{ textAlign: "center", paddingTop: "2%" }}> TODAY'S PANCHANG</h3>

                <p style={{ textAlign: "justify", padding: "2%", paddingLeft: "10%", paddingRight: "10%" }}>

                    According to astrology, when planets move from one position to another, it is a transit. So, when they change houses or zodiac signs, it shows some effects, which can be good or bad according to the individual birth chart. Moreover, as per the planetary transit 2023, each planet has its own motion around the Sun, and the transit period varies from one planet to another. Plus, during this time, some planets either get direct or retrograde, leading to multiple consequences

                    Generally, the transits caused by Rahu and Ketu lead to eclipses. On the other hand, the transit period of Jupiter and Saturn has a long-lasting effect. Transit of planets like the Moon and Mercury brings quick changes in the nearby planets. The planetary transits 2023 plus their impacts always come in the context of the birth chart of an individual. All the events— big or small, can be attributed to the movement of these planets. Not just this, Vedic astrology says that the transit of planets can also control various aspects of our life.

                    For example, the planet Moon is the significator of the mind, and the Sun is considered a royal planet and is responsible for the possibilities of government jobs and services in our horoscope. Ahead, if we speak of planet Venus, it is the Karak of love and marriage. Likewise, Mercury symbolizes our intellectual abilities, Jupiter majorly signifies health, and Saturn signifies our Karma or actions and produces results accordingly. That is why we need to know their transit dates and times to keep an eye on the changes we might see in our lives.


                    <br />

                </p>

                <br />





            </div>
            <div className='row' style={{ textAlign: "justify", padding: "1%", paddingLeft: "10%", paddingRight: "10%", }}>

                <h5 style={{ paddingTop: "2%" }}>Planetary transit 2023 and their duration</h5>

                <p >

                    You must be aware that not every planet moves at the same speed. Some of them move extremely slowly, while many move very fast. For example, the transit of planet Saturn is slow. However, comparatively, Mercury transit is a fast one. For an individual, it is hard to track the movements of any of these planets. Regardless of this factor, they are extremely vital to us. Moreover, their malefic or positive impacts are intense, influential, and noteworthy.





                </p>


                <div className='row'>

                    <div className='col-lg-7 col-md-8'>
                        <div className='row'>
                            <div className='col-6'>
                                <div className="day_col daily_col" style={{
                                    boxShadow: "0 0 10px 2px #ccc",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "18px",
                                    color: "#454545",
                                    borderRadius: "10px",
                                    flexDirection: "column",
                                    padding: "5px 0",
                                    border: "1px solid #ff8c00",
                                    backgroundColor: "#ff8c0020"
                                }}>
                                    <div className="panchang-date">
                                        <span className='sunrise'>
                                            <img src={sun} />
                                            Surise</span>
                                    </div>

                                </div>
                            </div>


                            <div className='col-6'>
                                <div className="day_col daily_col" style={{
                                    boxShadow: "0 0 10px 2px #ccc",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "18px",
                                    color: "#454545",
                                    borderRadius: "10px",
                                    flexDirection: "column",
                                    padding: "5px 0",
                                    border: "1px solid #ff8c00",
                                    backgroundColor: "#e3f1ff"
                                }}>
                                    <div className="panchang-date">
                                        <span className='sunrise'>
                                            <img src={moon} />
                                            Moonrise</span>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>

                </div>
                <br /><br />

                <div className="col-12" style={{ paddingTop: "1%" }}>
                    <table className="table table-bordered">
                        <thead>
                            <tr>


                                <th scope="col">Planetary Transit 2023</th>
                                <th scope="col">Transit duration</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                                <td>Tithi</td>
                                <td>Dwadashi</td>
                            </tr>
                            <tr>

                                <td>Nakshatra</td>
                                <td>Mrigashirsha upto 17:38</td>
                            </tr>
                            <tr>

                                <td>Mercury</td>
                                <td>21 Days</td>
                            </tr>
                            <tr>

                                <td>Jupiter</td>
                                <td>12.5 Months</td>
                            </tr>
                            <tr>

                                <td>Venus</td>
                                <td>26 Days</td>
                            </tr>
                            <tr>

                                <td>Saturn</td>
                                <td>2.5 Years</td>
                            </tr>

                        </tbody>
                    </table>


                </div>



                <label style={{ backgroundColor: "orange" }}>Inauspicious Timings (Ashubha Muhurat)</label>
                <div className="col-12">
                    <table className="table table-bordered">
                        <thead>
                            <tr>


                                <th scope="col">Planetary Transit 2023</th>
                                <th scope="col">Transit duration</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                                <td>Tithi</td>
                                <td>Dwadashi</td>
                            </tr>
                            <tr>

                                <td>Nakshatra</td>
                                <td>Mrigashirsha upto 17:38</td>
                            </tr>
                            <tr>

                                <td>Mercury</td>
                                <td>21 Days</td>
                            </tr>
                            <tr>

                                <td>Jupiter</td>
                                <td>12.5 Months</td>
                            </tr>
                            <tr>

                                <td>Venus</td>
                                <td>26 Days</td>
                            </tr>
                            <tr>

                                <td>Saturn</td>
                                <td>2.5 Years</td>
                            </tr>

                        </tbody>
                    </table>
                </div>


                <div className='col-6'></div>






            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-6' >
                        <img src={lagna} style={{ height: "500px", padding: "15px 15px 5px" }} />

                    </div>
                    <div className='col-6'>

                        <table className="table table-bordered">
                            <thead>
                                <tr>


                                    <th scope="col">Planetary Transit 2023</th>
                                    <th scope="col">Transit duration</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>

                                    <td style={{ width: "50%" }}>Tithi</td>
                                    <td>Dwadashi</td>
                                </tr>
                                <tr>

                                    <td>Nakshatra</td>
                                    <td>Mrigashirsha upto 17:38</td>
                                </tr>
                                <tr>

                                    <td>Mercury</td>
                                    <td>21 Days</td>
                                </tr>


                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            <br />< br />
            <div className='container'>
                {/* <label style={{backgroundColor:"orange" ,width:"100%"}}>Inauspicious Timings (Ashubha Muhurat)</label> */}
                <div className="col-12">
                    <table className="table table-bordered" >
                        <thead >
                            <tr>


                                <th scope="col">Planetary Transit 2023</th>
                                <th scope="col">Transit duration</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>

                                <td>Tithi</td>
                                <td>Dwadashi</td>
                            </tr>
                            <tr>

                                <td>Nakshatra</td>
                                <td>Mrigashirsha upto 17:38</td>
                            </tr>
                            <tr>

                                <td>Mercury</td>
                                <td>21 Days</td>
                            </tr>
                            <tr>

                                <td>Jupiter</td>
                                <td>12.5 Months</td>
                            </tr>
                            <tr>

                                <td>Venus</td>
                                <td>26 Days</td>
                            </tr>
                            <tr>

                                <td>Saturn</td>
                                <td>2.5 Years</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>


            <div style={{ textAlign: "justify", paddingLeft: "10%", paddingRight: "10%", }}>

                <p>These days with the help of various astrology websites, you can learn about planetary transit. Long Mahadasha prescribes a wide time frame where Antar Dasha reduces the results and timing. The transit of planets during the Mahadasha further most likely influences the benefic consequences of that particular planet.</p>
            </div>




            <section className="as_whychoose_wrapper" style={{ cursor: "pointer" }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 text-center">
                            <h1 className="as_heading">Want to know more about kundli</h1>

                            <p className="as_font14 as_padderTop20 as_padderBottom50">Our professional and experienced Astrologers study the movements of the planets, sun, moon, and <br />stars and predict as to how these movements are influencing and/or can have an influence on people's lives.</p>
                        </div>
                        <div className="col-lg-12">
                            <ul className="as_choose_ul">



                                <li style={{ paddingLeft: "10%" }}>

                                    <a href="/"> <button className="as_btn" href="/kundlimatching" style={{ marginLeft: "50%" }}>Click Here </button></a>

                                </li>
                                <li style={{ paddingLeft: "25%" }}>

                                    <a href="">   <button className="as_btn" style={{ marginLeft: "50%", width: "20%" }}>Chat With Astrologer </button></a>

                                </li>
                                <li style={{ paddingLeft: "30%" }}>

                                    <a href="">    <button className="as_btn" style={{ marginLeft: "50%" }}>Talk To Astrologer </button></a>

                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row" style={{ marginTop: "2%" }}>
                <h4 style={{ paddingLeft: "10%", textAlign: "center", margingTop: "5%" }}>FAQs</h4>

                <h5 style={{ paddingLeft: "10%" }}>
                    Q 1. Which planetary transit in 2023 is most important?
                </h5>
                <p style={{ paddingLeft: "10%", paddingRight: "10%", textAlign: "justify" }}>
                    In 2023, the Saturn transit is the most important one. It is because the effect of this planetary transit remains in the life of the person for 2.5 years— for the longest duration.
                </p>

                < h5 style={{ paddingLeft: "10%" }}>
                    Q 2. What is the duration of planetary transits?
                </h5>
                <p style={{ paddingLeft: "10%", paddingRight: "10%", textAlign: "justify" }}>
                    The longer the transit period of the planet, the more influential it will be in your horoscope or birth chart. Planetary transits, direct or retrograde, last anywhere from a few days (for the Moon) to 2 to 2.5 years (for Saturn).

                </p>

                < h5 style={{ paddingLeft: "10%" }}>
                    Q 3.Which planet is favorable for love in 2023?
                </h5>
                <p style={{ paddingLeft: "10%", paddingRight: "10%", textAlign: "justify" }}>
                    For an individual, if the Jupiter transit in 2023 occurs in the 1st and 7th house, it shall provide enthusiasm and enhancement to one's outlook towards all relationships along with personal progress. You will more willingly accept supportive individuals into your life who help you thrive in hard times, which often include a love affair.
                </p>
                < h5 style={{ paddingLeft: "10%" }}>
                    Q 4. Which house is favorable for Jupiter in 2023?
                </h5>
                <p style={{ paddingLeft: "10%", paddingRight: "10%", textAlign: "justify" }}>
                    Transit of Jupiter in 2023 will give good results when placed in 1st, 5th, 8th, 9th, or 12th houses. However, the 6th, 7th, and 10th houses are bad houses for it. Moreover, Jupiter might give inauspicious results when Venus or Mercury is in the tenth house of the birth chart of a person.
                </p>

                < h5 style={{ paddingLeft: "10%" }}>
                    Q 5. What is Dasha in Kundli analysis?
                </h5>
                <p style={{ paddingLeft: "10%", paddingRight: "10%", textAlign: "justify" }}>
                    Dasha is a vital phase of a planet in the life of the native. And according to astrology, there are 43 different types of Dasha systems. When the planet is powerful or settled in its exaltation sign, the Dasha is known as the Poorna Dasha. However, if the planet is powerless or in poor condition, the Dasha is recognized as the Rikta Dasha.

                </p>










            </div>



        </div>
    )
}

export default TodayPanchang