import React from 'react'

function register() {
    return (
        <div>


            <section className="as_breadcrum_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h1>Register Now</h1>

                            <ul className="breadcrumb">
                                <li><a href="home">Home</a></li>
                                <li>Register Now</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="as_contact_wrapper as_padderBottom40 ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">


                        </div>


                        <div className="container ">
                            <div className="row">
                                <div className="col-md-4 offset-md-4 border p-4 shadow bg-light">
                                    <div className="col-12">
                                    </div>
                                    <form action="">

                                        <center>  <h3>Register Now</h3>  </center>
                                        <center>   </center>
                                        <center> Create your Account. It's Free and Only Takes a Minute</center>
                                        <div className="row g-3">
                                            <div className="col-md-12">
                                                <input type="text" className="form-control" placeholder="First Name" />
                                            </div>
                                            <div className="col-md-12">
                                                <input type="text" className="form-control" placeholder="Last Name" />
                                            </div>
                                            <div className="col-md-12">
                                                <input type="tel" className="form-control" placeholder="Phone Number" />
                                            </div>
                                            <div className="col-md-12">
                                                <input type="email" className="form-control" placeholder="Enter Email" />
                                            </div>

                                            <div className="col-md-12">
                                                <input type="text" className="form-control" placeholder="Password" />
                                            </div>

                                            <div className="col-md-12">
                                                <input type="text" className="form-control" placeholder="Confirm Password" />
                                            </div>


                                            <div className="col-12 mt-2">
                                                <center> <a href="about.html" className="as_btn"> Register Now</a> </center>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>





        </div>
    )
}

export default register