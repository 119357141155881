import React from 'react'
// import Card from "react-bootstrap/Card";
// import Card from 'react-bootstrap/Card';
// import Button from 'react-bootstrap/Button';
import { Data } from './data'
import Carousel from 'react-grid-carousel'
import Banner from '../banner.jpg';
// import Style from './Style.css';



function card() {
  return (

    <>

      <div className="row" style={{ paddingTop: "5%", backgroundColor: "#D3D3D3" }}>
        <Carousel cols={6} rows={1} gap={10} loop>
          <Carousel.Item>
            {/* <img width="100%" src="https://picsum.photos/800/600?random=1" /> */}
            <div className="container" style={{ padding: "4px 16px;", height: '25%', align: "center", paddingTop: "10%", backgroundColor: "#eee;" }}>
              <i className="fa fa-phone fa-3x" aria-hidden="true" style={{ height: '30%', width: '30%' }}></i>
              <h4><b>Free Kundali</b></h4>
              <p>Loreum ipsum lorem ipsum </p>

            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="container" style={{ padding: "4px 16px;", height: '25%', align: "center", paddingTop: "10%" }}>
              <i className="fa fa-comments fa-3x" aria-hidden="true" style={{ height: '30%', width: '30%' }}></i>
              <h4><b>Today's Horoscope</b></h4>
              <p>Loreum ipsum lorem ipsum </p>

            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="container" style={{ padding: "4px 16px;", height: '25%', align: "center", paddingTop: "10%" }}>
              <i className="fa fa-phone fa-3x" aria-hidden="true" style={{ height: '30%', width: '30%' }}></i>
              <h4><b>Compatibility</b></h4>
              <p>Loreum ipsum lorem ipsum </p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="container" style={{ padding: "4px 16px;", height: '25%', align: "center", paddingTop: "10%" }}>
              <i className="fa fa-phone fa-3x" aria-hidden="true" style={{ height: '30%', width: '30%' }}></i>
              <h4><b>Festival 2023</b></h4>

            </div>
          </Carousel.Item>
          <Carousel.Item>
            {/* <img width="100%" src="https://picsum.photos/800/600?random=3" /> */}
            <div className="container" style={{ padding: "4px 16px;", height: '25%', align: "center", paddingTop: "10%" }}>
              <i className="fa fa-phone fa-3x" aria-hidden="true" style={{ height: '30%', width: '30%' }}></i>
              <h4><b>Kundali Matching</b></h4>

            </div>
          </Carousel.Item>
          <Carousel.Item>
            {/* <img width="100%" src="https://picsum.photos/800/600?random=3" /> */}
            <div className="container" style={{ padding: "4px 16px;", height: '25%', align: "center", paddingTop: "10%" }}>
              <i className="fa fa-phone fa-3x" aria-hidden="true" style={{ height: '30%', width: '30%' }}></i>
              <h4><b>Chinese Horoscope</b></h4>

            </div>
          </Carousel.Item>
          <Carousel.Item>
            {/* anything you want to show in the grid */}
            <div className="container" style={{ padding: "4px 16px;", height: '25%', align: "center", paddingTop: "10%" }}>
              <i className="fa fa-phone fa-3x" aria-hidden="true" style={{ height: '30%', width: '30%' }}></i>
              <h4><b>Today Panchang</b></h4>

            </div>
          </Carousel.Item>
          {/* ... */}
        </Carousel>
      </div>

      {/* <div id="carouselExampleControls"             className="carousel slide" data-ride="carousel">
  <div             className="carousel-inner">
    <div             className="carousel-item active">
      <div             className="cards-wrapper">
      <div             className="card">
        <img src="..."             className="card-img-top" alt="..."/>
        <div             className="card-body">
          <h5             className="card-title">Card title</h5>
          <p             className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
          <a href="#"             className="btn btn-primary">Go somewhere</a>
        </div>
      </div>
      <div             className="card d-none d-md-block">
        <img src="..."             className="card-img-top" alt="..." />
        <div             className="card-body">
          <h5             className="card-title">Card title</h5>
          <p             className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
          <a href="#"             className="btn btn-primary">Go somewhere</a>
        </div>
      </div>
      <div             className="card d-none d-md-block">
        <img src="..."             className="card-img-top" alt="..." />
        <div             className="card-body">
          <h5             className="card-title">Card title</h5>
          <p             className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
          <a href="#"             className="btn btn-primary">Go somewhere</a>
        </div>
      </div>
    </div>
    </div>
    <div             className="carousel-item">
      <div             className="cards-wrapper">
        <div             className="card">
          <img src="..."             className="card-img-top" alt="..." />
          <div             className="card-body">
            <h5             className="card-title">Card title</h5>
            <p             className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            <a href="#"             className="btn btn-primary">Go somewhere</a>
          </div>
        </div>
        <div             className="card d-none d-md-block">
          <img src="..."             className="card-img-top" alt="..." />
          <div             className="card-body">
            <h5             className="card-title">Card title</h5>
            <p             className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            <a href="#"             className="btn btn-primary">Go somewhere</a>
          </div>
        </div>
        <div             className="card d-none d-md-block">
          <img src="..."             className="card-img-top" alt="..." />
          <div             className="card-body">
            <h5             className="card-title">Card title</h5>
            <p             className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            <a href="#"             className="btn btn-primary">Go somewhere</a>
          </div>
        </div>
      </div>
    </div>
    <div             className="carousel-item">
      <div             className="cards-wrapper">
        <div             className="card">
          <img src="..."             className="card-img-top" alt="..." />
          <div             className="card-body">
            <h5             className="card-title">Card title</h5>
            <p             className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            <a href="#"             className="btn btn-primary">Go somewhere</a>
          </div>
        </div>
        <div             className="card d-none d-md-block">
          <img src="..."             className="card-img-top" alt="..." />
          <div             className="card-body">
            <h5             className="card-title">Card title</h5>
            <p             className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            <a href="#"             className="btn btn-primary">Go somewhere</a>
          </div>
        </div>
        <div             className="card d-none d-md-block">
          <img src="..."             className="card-img-top" alt="..." />
          <div             className="card-body">
            <h5             className="card-title">Card title</h5>
            <p             className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
            <a href="#"             className="btn btn-primary">Go somewhere</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a             className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span             className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span             className="sr-only">Previous</span>
  </a>
  <a             className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span             className="carousel-control-next-icon" aria-hidden="true"></span>
    <span             className="sr-only">Next</span>
  </a>
</div>
       */}









    </>





  );




}

export default card