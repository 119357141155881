import React from 'react'
import { Outlet, Link } from "react-router-dom";
import H1 from '../Assets/images/svg/h1.svg';
import H2 from '../Assets/images/svg/h2.svg';
import H3 from '../Assets/images/svg/h3.svg';
import H4 from '../Assets/images/svg/h4.svg';
import H5 from '../Assets/images/svg/h5.svg';
import H6 from '../Assets/images/svg/h6.svg';
import H7 from '../Assets/images/svg/h7.svg';
import H8 from '../Assets/images/svg/h8.svg';
import H9 from '../Assets/images/svg/h9.svg';
import H10 from '../Assets/images/svg/h10.svg';
import H11 from '../Assets/images/svg/h11.svg';
import H12 from '../Assets/images/svg/h12.svg';

function Horoscope2023() {
    return (
        <div>


            <section className="as_breadcrum_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center" style={{ textAlign: "center" }}>
                            <h1 style={{ paddingTop: "5px" }}> HOROSCOPE 2023</h1>

                            <ul className="breadcrumb">
                                <li><a href="/">Home</a></li>

                            </ul>
                        </div>
                    </div>
                </div>
            </section>



            <div className='row'>

                <h3 style={{ textAlign: "center", paddingTop: "2%" }}>  Horoscope 2023 predictions for all zodiac signs</h3>

                <p style={{ textAlign: "justify", padding: "2%", paddingLeft: "10%", paddingRight: "10%" }}>

                    The New Year 2023 is finally here, and we have a lot to look forward to. From setting new goals to reflecting on the old ones, the New year brings us an opportunity to set things right back on track, and to guide you on the entire journey is your Horoscope 2023 by India’s best astrologers.

                    When it comes to life, all of us have some goals to look forward to. These goals could be as simple as starting your own business in 2023 to finding love. As much as we wish to achieve these goals for ourselves, the restraints come from within and our surroundings. Clearly, not everything in life goes according to our plan. And as much as it is a good thing, the same, at times, is frustrating as well. In such cases, we need to reflect on what we are doing wrong or simply what is happening wrong with us. And to help you in doing so is your yearly horoscope 2023.


                    <br />
                    Planets in one's Kundli are never stationary. They keep moving from one house to another. All a person should do is identify when the planet is favourable for him and when not to plan things accordingly. Hence, trying to work on your love life when Venus is auspicious in your chart will give you more returns than doing it when Venus is not favourable. In fact, if you can’t wait but have to try even when the planets are not supportive of a particular task, then you can simply practice some remedies to strengthen the weak planet before going forward with your work. All these favourable and unfavourable timings and remedies are a part of the horoscope 2023, which a user can read to make an informed decision about his future activities.

                </p>
             




            </div>
            <section className='as_customer_wrapper' style={{ backgroundImage: "url('../images/bg3.jpg')" }}>

                <div class="container">
                <div className='row' style={{ paddingBottom: "2%" }}>
                    <h5 style={{ textAlign: "center" }}><b>Aries Horoscope 2023 </b></h5>
                    <div className='col-md-3 col-12' >

                        <div className="as_sign_box text-center">

                            <a href="">
                                <span className="as_sign">
                                    <img src={H1} alt="" />
                                </span>
                              <div>
                                    <h5>Aries</h5>
                                    <p>Mar 21 - Apr 19</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-md-9 col-12' >
                        <p style={{paddingTop:"20px",textAlign:"justify"}}>
                            Sometimes, Aries natives get highly motivated and strong-opinionated, which might get them into problems easily. However, sometimes the same dominant factor can help them win some extremely hard battles in their lives. The Aries yearly horoscope 2023 predicts that even though you would be on your impulses, the year will be a strong one for you. Sometimes, it would add to your charm, while sometimes, it might challenge you to become better in life. Planetary support would be there with you. But, the year would make you more prepared for the upcoming hurdles and situations in your life. Hard times are to test you. Battling the ups and the downs will be too many. But you are Aries, and you know how to deal with the emotional as well as physical ups and downs. However, beware of some issues that may come with Rahu in the third quarter. You can rely on Jupiter as it would be an aid to your life all through the year.


                        </p>
                    </div>


                </div>
                </div>
               

                <div className='row' style={{ paddingBottom: "2%" }}>
                    <h5 style={{ textAlign: "center" }}><b>Taurus Horoscope 2023 </b></h5>
                    <div className='col-3' style={{ paddingLeft: "10%", textAlign: "center" }}>

                        <div className="as_sign_box text-center">

                            <a href="">
                                <span className="as_sign">
                                    <img src={H2} alt="" />
                                </span>
                                <div>
                                    <h5>Taurus</h5>
                                    <p>Mar 21 - Apr 19</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-9' style={{ paddingRight: "10%", textAlign: "justify" }}>
                        <p>
                            Sometimes, Aries natives get highly motivated and strong-opinionated, which might get them into problems easily. However, sometimes the same dominant factor can help them win some extremely hard battles in their lives. The Aries yearly horoscope 2023 predicts that even though you would be on your impulses, the year will be a strong one for you. Sometimes, it would add to your charm, while sometimes, it might challenge you to become better in life. Planetary support would be there with you. But, the year would make you more prepared for the upcoming hurdles and situations in your life. Hard times are to test you. Battling the ups and the downs will be too many. But you are Aries, and you know how to deal with the emotional as well as physical ups and downs. However, beware of some issues that may come with Rahu in the third quarter. You can rely on Jupiter as it would be an aid to your life all through the year.


                        </p>
                    </div>


                </div>

                <div className='row' style={{ paddingBottom: "2%" }}>
                    <h5 style={{ textAlign: "center" }}><b>Gemini Horoscope 2023 </b></h5>
                    <div className='col-3' style={{ paddingLeft: "10%", textAlign: "center" }}>

                        <div className="as_sign_box text-center">

                            <a href="">
                                <span className="as_sign">
                                    <img src={H3} alt="" />
                                </span>
                                <div>
                                    <h5>Gemini</h5>
                                    <p>Mar 21 - Apr 19</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-9' style={{ paddingRight: "10%", textAlign: "justify" }}>
                        <p>
                            Sometimes, Aries natives get highly motivated and strong-opinionated, which might get them into problems easily. However, sometimes the same dominant factor can help them win some extremely hard battles in their lives. The Aries yearly horoscope 2023 predicts that even though you would be on your impulses, the year will be a strong one for you. Sometimes, it would add to your charm, while sometimes, it might challenge you to become better in life. Planetary support would be there with you. But, the year would make you more prepared for the upcoming hurdles and situations in your life. Hard times are to test you. Battling the ups and the downs will be too many. But you are Aries, and you know how to deal with the emotional as well as physical ups and downs. However, beware of some issues that may come with Rahu in the third quarter. You can rely on Jupiter as it would be an aid to your life all through the year.


                        </p>
                    </div>


                </div>

                <div className='row' style={{ paddingBottom: "2%" }}>
                    <h5 style={{ textAlign: "center" }}><b>Cancer Horoscope 2023 </b></h5>
                    <div className='col-3' style={{ paddingLeft: "10%", textAlign: "center" }}>

                        <div className="as_sign_box text-center">

                            <a href="">
                                <span className="as_sign">
                                    <img src={H4} alt="" />
                                </span>
                                <div>
                                    <h5>Cancer</h5>
                                    <p>Mar 21 - Apr 19</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-9' style={{ paddingRight: "10%", textAlign: "justify" }}>
                        <p>
                            Sometimes, Aries natives get highly motivated and strong-opinionated, which might get them into problems easily. However, sometimes the same dominant factor can help them win some extremely hard battles in their lives. The Aries yearly horoscope 2023 predicts that even though you would be on your impulses, the year will be a strong one for you. Sometimes, it would add to your charm, while sometimes, it might challenge you to become better in life. Planetary support would be there with you. But, the year would make you more prepared for the upcoming hurdles and situations in your life. Hard times are to test you. Battling the ups and the downs will be too many. But you are Aries, and you know how to deal with the emotional as well as physical ups and downs. However, beware of some issues that may come with Rahu in the third quarter. You can rely on Jupiter as it would be an aid to your life all through the year.


                        </p>
                    </div>


                </div>

                <div className='row' style={{ paddingBottom: "2%" }}>
                    <h5 style={{ textAlign: "center" }}><b>Leo Horoscope 2023 </b></h5>
                    <div className='col-3' style={{ paddingLeft: "10%", textAlign: "center" }}>

                        <div className="as_sign_box text-center">

                            <a href="">
                                <span className="as_sign">
                                    <img src={H5} alt="" />
                                </span>
                                <div>
                                    <h5>Leo</h5>
                                    <p>Mar 21 - Apr 19</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-9' style={{ paddingRight: "10%", textAlign: "justify" }}>
                        <p>
                            Sometimes, Aries natives get highly motivated and strong-opinionated, which might get them into problems easily. However, sometimes the same dominant factor can help them win some extremely hard battles in their lives. The Aries yearly horoscope 2023 predicts that even though you would be on your impulses, the year will be a strong one for you. Sometimes, it would add to your charm, while sometimes, it might challenge you to become better in life. Planetary support would be there with you. But, the year would make you more prepared for the upcoming hurdles and situations in your life. Hard times are to test you. Battling the ups and the downs will be too many. But you are Aries, and you know how to deal with the emotional as well as physical ups and downs. However, beware of some issues that may come with Rahu in the third quarter. You can rely on Jupiter as it would be an aid to your life all through the year.


                        </p>
                    </div>


                </div>

                <div className='row' style={{ paddingBottom: "2%" }}>
                    <h5 style={{ textAlign: "center" }}><b>Virgo Horoscope 2023 </b></h5>
                    <div className='col-3' style={{ paddingLeft: "10%", textAlign: "center" }}>

                        <div className="as_sign_box text-center">

                            <a href="">
                                <span className="as_sign">
                                    <img src={H6} alt="" />
                                </span>
                                <div>
                                    <h5>Virgo</h5>
                                    <p>Mar 21 - Apr 19</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-9' style={{ paddingRight: "10%", textAlign: "justify" }}>
                        <p>
                            Sometimes, Aries natives get highly motivated and strong-opinionated, which might get them into problems easily. However, sometimes the same dominant factor can help them win some extremely hard battles in their lives. The Aries yearly horoscope 2023 predicts that even though you would be on your impulses, the year will be a strong one for you. Sometimes, it would add to your charm, while sometimes, it might challenge you to become better in life. Planetary support would be there with you. But, the year would make you more prepared for the upcoming hurdles and situations in your life. Hard times are to test you. Battling the ups and the downs will be too many. But you are Aries, and you know how to deal with the emotional as well as physical ups and downs. However, beware of some issues that may come with Rahu in the third quarter. You can rely on Jupiter as it would be an aid to your life all through the year.


                        </p>
                    </div>


                </div>

                <div className='row' style={{ paddingBottom: "2%" }}>
                    <h5 style={{ textAlign: "center" }}><b>Libra  Horoscope 2023 </b></h5>
                    <div className='col-3' style={{ paddingLeft: "10%", textAlign: "center" }}>

                        <div className="as_sign_box text-center">

                            <a href="">
                                <span className="as_sign">
                                    <img src={H7} alt="" />
                                </span>
                                <div>
                                    <h5>Libra </h5>
                                    <p>Mar 21 - Apr 19</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-9' style={{ paddingRight: "10%", textAlign: "justify" }}>
                        <p>
                            Sometimes, Aries natives get highly motivated and strong-opinionated, which might get them into problems easily. However, sometimes the same dominant factor can help them win some extremely hard battles in their lives. The Aries yearly horoscope 2023 predicts that even though you would be on your impulses, the year will be a strong one for you. Sometimes, it would add to your charm, while sometimes, it might challenge you to become better in life. Planetary support would be there with you. But, the year would make you more prepared for the upcoming hurdles and situations in your life. Hard times are to test you. Battling the ups and the downs will be too many. But you are Aries, and you know how to deal with the emotional as well as physical ups and downs. However, beware of some issues that may come with Rahu in the third quarter. You can rely on Jupiter as it would be an aid to your life all through the year.


                        </p>
                    </div>


                </div>

                <div className='row' style={{ paddingBottom: "2%" }}>
                    <h5 style={{ textAlign: "center" }}><b>Scorpio Horoscope 2023 </b></h5>
                    <div className='col-3' style={{ paddingLeft: "10%", textAlign: "center" }}>

                        <div className="as_sign_box text-center">

                            <a href="">
                                <span className="as_sign">
                                    <img src={H8} alt="" />
                                </span>
                                <div>
                                    <h5>Scorpio</h5>
                                    <p>Mar 21 - Apr 19</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-9' style={{ paddingRight: "10%", textAlign: "justify" }}>
                        <p>
                            Sometimes, Aries natives get highly motivated and strong-opinionated, which might get them into problems easily. However, sometimes the same dominant factor can help them win some extremely hard battles in their lives. The Aries yearly horoscope 2023 predicts that even though you would be on your impulses, the year will be a strong one for you. Sometimes, it would add to your charm, while sometimes, it might challenge you to become better in life. Planetary support would be there with you. But, the year would make you more prepared for the upcoming hurdles and situations in your life. Hard times are to test you. Battling the ups and the downs will be too many. But you are Aries, and you know how to deal with the emotional as well as physical ups and downs. However, beware of some issues that may come with Rahu in the third quarter. You can rely on Jupiter as it would be an aid to your life all through the year.


                        </p>
                    </div>


                </div>
                <div className='row' style={{ paddingBottom: "2%" }}>
                    <h5 style={{ textAlign: "center" }}><b>Sagittarius Horoscope 2023 </b></h5>
                    <div className='col-3' style={{ paddingLeft: "10%", textAlign: "center" }}>

                        <div className="as_sign_box text-center">

                            <a href="">
                                <span className="as_sign">
                                    <img src={H9} alt="" />
                                </span>
                                <div>
                                    <h5>Sagittarius</h5>
                                    <p>Mar 21 - Apr 19</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-9' style={{ paddingRight: "10%", textAlign: "justify" }}>
                        <p>
                            Sometimes, Aries natives get highly motivated and strong-opinionated, which might get them into problems easily. However, sometimes the same dominant factor can help them win some extremely hard battles in their lives. The Aries yearly horoscope 2023 predicts that even though you would be on your impulses, the year will be a strong one for you. Sometimes, it would add to your charm, while sometimes, it might challenge you to become better in life. Planetary support would be there with you. But, the year would make you more prepared for the upcoming hurdles and situations in your life. Hard times are to test you. Battling the ups and the downs will be too many. But you are Aries, and you know how to deal with the emotional as well as physical ups and downs. However, beware of some issues that may come with Rahu in the third quarter. You can rely on Jupiter as it would be an aid to your life all through the year.


                        </p>
                    </div>


                </div>
                <div className='row' style={{ paddingBottom: "2%" }}>
                    <h5 style={{ textAlign: "center" }}><b>Capricorn Horoscope 2023 </b></h5>
                    <div className='col-3' style={{ paddingLeft: "10%", textAlign: "center" }}>

                        <div className="as_sign_box text-center">

                            <a href="">
                                <span className="as_sign">
                                    <img src={H10} alt="" />
                                </span>
                                <div>
                                    <h5>Capricorn</h5>
                                    <p>Mar 21 - Apr 19</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-9' style={{ paddingRight: "10%", textAlign: "justify" }}>
                        <p>
                            Sometimes, Aries natives get highly motivated and strong-opinionated, which might get them into problems easily. However, sometimes the same dominant factor can help them win some extremely hard battles in their lives. The Aries yearly horoscope 2023 predicts that even though you would be on your impulses, the year will be a strong one for you. Sometimes, it would add to your charm, while sometimes, it might challenge you to become better in life. Planetary support would be there with you. But, the year would make you more prepared for the upcoming hurdles and situations in your life. Hard times are to test you. Battling the ups and the downs will be too many. But you are Aries, and you know how to deal with the emotional as well as physical ups and downs. However, beware of some issues that may come with Rahu in the third quarter. You can rely on Jupiter as it would be an aid to your life all through the year.


                        </p>
                    </div>


                </div>
                <div className='row' style={{ paddingBottom: "2%" }}>
                    <h5 style={{ textAlign: "center" }}><b>Scorpio Horoscope 2023 </b></h5>
                    <div className='col-3' style={{ paddingLeft: "10%", textAlign: "center" }}>

                        <div className="as_sign_box text-center">

                            <a href="">
                                <span className="as_sign">
                                    <img src={H11} alt="" />
                                </span>
                                <div>
                                    <h5>Aquarius</h5>
                                    <p>Mar 21 - Apr 19</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-9' style={{ paddingRight: "10%", textAlign: "justify" }}>
                        <p>
                            Sometimes, Aries natives get highly motivated and strong-opinionated, which might get them into problems easily. However, sometimes the same dominant factor can help them win some extremely hard battles in their lives. The Aries yearly horoscope 2023 predicts that even though you would be on your impulses, the year will be a strong one for you. Sometimes, it would add to your charm, while sometimes, it might challenge you to become better in life. Planetary support would be there with you. But, the year would make you more prepared for the upcoming hurdles and situations in your life. Hard times are to test you. Battling the ups and the downs will be too many. But you are Aries, and you know how to deal with the emotional as well as physical ups and downs. However, beware of some issues that may come with Rahu in the third quarter. You can rely on Jupiter as it would be an aid to your life all through the year.


                        </p>
                    </div>


                </div>
                <div className='row' style={{ paddingBottom: "2%" }}>
                    <h5 style={{ textAlign: "center" }}><b>Pisces Horoscope 2023 </b></h5>
                    <div className='col-3' style={{ paddingLeft: "10%", textAlign: "center" }}>

                        <div className="as_sign_box text-center">

                            <a href="">
                                <span className="as_sign">
                                    <img src={H12} alt="" />
                                </span>
                                <div>
                                    <h5>Pisces</h5>
                                    <p>Mar 21 - Apr 19</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className='col-9' style={{ paddingRight: "10%", textAlign: "justify" }}>
                        <p>
                            Sometimes, Aries natives get highly motivated and strong-opinionated, which might get them into problems easily. However, sometimes the same dominant factor can help them win some extremely hard battles in their lives. The Aries yearly horoscope 2023 predicts that even though you would be on your impulses, the year will be a strong one for you. Sometimes, it would add to your charm, while sometimes, it might challenge you to become better in life. Planetary support would be there with you. But, the year would make you more prepared for the upcoming hurdles and situations in your life. Hard times are to test you. Battling the ups and the downs will be too many. But you are Aries, and you know how to deal with the emotional as well as physical ups and downs. However, beware of some issues that may come with Rahu in the third quarter. You can rely on Jupiter as it would be an aid to your life all through the year.


                        </p>
                    </div>


                </div>
            </section>

            <section className="as_whychoose_wrapper" style={{ cursor: "pointer" }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 text-center">
                            <h1 className="as_heading">Want to know more about kundli</h1>

                            <p className="as_font14 as_padderTop20 as_padderBottom50">Our professional and experienced Astrologers study the movements of the planets, sun, moon, and <br />stars and predict as to how these movements are influencing and/or can have an influence on people's lives.</p>
                        </div>
                        <div className="col-lg-12">
                            <ul className="as_choose_ul">



                                {/* <li style={{ paddingLeft: "10%" }}>

                                    <a href="/"> <button className="as_btn" href="/kundlimatching" style={{ marginLeft: "50%" }}>Click Here </button></a>

                                </li>
                                <li style={{ paddingLeft: "25%" }}>

                                    <a href="">   <button className="as_btn" style={{ marginLeft: "50%", width: "20%" }}>Chat With Astrologer </button></a>

                                </li>
                                <li style={{ paddingLeft: "30%" }}>

                                    <a href="">    <button className="as_btn" style={{ marginLeft: "50%" }}>Talk To Astrologer </button></a>

                                </li> */}

                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row" style={{ marginTop: "2%" }}>
                <h4 style={{ textAlign: "center", margingTop: "5%" }}>Yearly horoscope 2023 FAQs</h4>

                <h5 style={{ paddingLeft: "10%" }}>
                    Q 1. Which planetary transit in 2023 is most important?
                </h5>
                <p style={{ paddingLeft: "10%", paddingRight: "10%", textAlign: "justify" }}>
                    In 2023, the Saturn transit is the most important one. It is because the effect of this planetary transit remains in the life of the person for 2.5 years— for the longest duration.
                </p>

                < h5 style={{ paddingLeft: "10%" }}>
                    Q 2. What is the duration of planetary transits?
                </h5>
                <p style={{ paddingLeft: "10%", paddingRight: "10%", textAlign: "justify" }}>
                    The longer the transit period of the planet, the more influential it will be in your horoscope or birth chart. Planetary transits, direct or retrograde, last anywhere from a few days (for the Moon) to 2 to 2.5 years (for Saturn).

                </p>

                < h5 style={{ paddingLeft: "10%" }}>
                    Q 3.Which planet is favorable for love in 2023?
                </h5>
                <p style={{ paddingLeft: "10%", paddingRight: "10%", textAlign: "justify" }}>
                    For an individual, if the Jupiter transit in 2023 occurs in the 1st and 7th house, it shall provide enthusiasm and enhancement to one's outlook towards all relationships along with personal progress. You will more willingly accept supportive individuals into your life who help you thrive in hard times, which often include a love affair.
                </p>
                < h5 style={{ paddingLeft: "10%" }}>
                    Q 4. Which house is favorable for Jupiter in 2023?
                </h5>
                <p style={{ paddingLeft: "10%", paddingRight: "10%", textAlign: "justify" }}>
                    Transit of Jupiter in 2023 will give good results when placed in 1st, 5th, 8th, 9th, or 12th houses. However, the 6th, 7th, and 10th houses are bad houses for it. Moreover, Jupiter might give inauspicious results when Venus or Mercury is in the tenth house of the birth chart of a person.
                </p>

                < h5 style={{ paddingLeft: "10%" }}>
                    Q 5. What is Dasha in Kundli analysis?
                </h5>
                <p style={{ paddingLeft: "10%", paddingRight: "10%", textAlign: "justify" }}>
                    Dasha is a vital phase of a planet in the life of the native. And according to astrology, there are 43 different types of Dasha systems. When the planet is powerful or settled in its exaltation sign, the Dasha is known as the Poorna Dasha. However, if the planet is powerless or in poor condition, the Dasha is recognized as the Rikta Dasha.

                </p>










            </div>
        </div>
    )
}

export default Horoscope2023