import React from 'react'

function Blogs() {
    return (
        <div>
            <section className="as_breadcrum_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h1>Blog</h1>
                            <ul className="breadcrumb">
                                <li><a href="home">Home</a></li>
                                <li>Blog</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="as_blog_wrapper as_padderBottom90 as_padderTop80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                            <div className="as_shop_sidebar">
                                <div className="as_widget as_search_widget">
                                    <input type="text" name="" className="form-control" id="" placeholder="Search" />
                                    <span><img src="assets/images/svg/search.svg" alt="" /></span>
                                </div>
                                <div className="as_widget as_category_widget">
                                    <h3 className="as_widget_title">Services</h3>

                                    <ul>
                                        <li><a href="">Kundali Dosha</a></li>
                                        <li><a href="">Face Reading</a></li>
                                        <li><a href="">Daily Horoscope</a></li>
                                        <li><a href="">Personal Consultation</a></li>
                                        <li><a href="">gem &amp; Stone</a></li>
                                        <li><a href="">Manglik Dosha</a></li>
                                        <li><a href="">Vastu Shastra</a></li>
                                        <li><a href="">planets</a></li>
                                        <li><a href="">Numerology</a></li>
                                        <li><a href="">tarot cards</a></li>
                                    </ul>
                                </div>
                                {/* <div    className="as_widget as_category_widget">
                                <h3    className="as_widget_title">Archives</h3>

                                <ul>
                                    <li><a href="">January 2020 (20)</a></li>
                                    <li><a href="">February 2020 (15)</a></li>
                                    <li><a href="">March 2020 (5)</a></li>
                                    <li><a href="">April 2020 (3)</a></li>
                                    <li><a href="">May 2020 (18)</a></li>
                                    <li><a href="">June 2020 (7)</a></li>
                                </ul>
                            </div> */}
                                <div className="as_widget as_workinghours_widget">
                                    <h3 className="as_widget_title">Working Hours</h3>

                                    <ul>
                                        <li><a href=""><span>Monday</span> <span>09:00 - 12:00</span></a></li>
                                        <li><a href=""><span>Tuesday</span> <span>09:00 - 12:00</span></a></li>
                                        <li><a href=""><span>Wednesday</span> <span>09:00 - 12:00</span></a></li>
                                        <li><a href=""><span>Thursday</span> <span>09:00 - 12:00</span></a></li>
                                        <li><a href=""><span>Friday</span> <span>09:00 - 12:00</span></a></li>
                                        <li><a href=""><span>Saturday</span> <span>09:00 - 12:00</span></a></li>
                                        <li><a href=""><span>Sunday</span> <span>09:00 - 12:00</span></a></li>
                                    </ul>
                                </div>

                                <div className="as_widget as_product_widget as_post_widget">
                                    <h3 className="as_widget_title">Recent Posts</h3>

                                    <ul>
                                        <li className="as_product">
                                            <a href="shop_single.html">
                                                <span className="as_productimg">
                                                    <img src="assets/images/post1.jpg" alt="" />
                                                </span>
                                                <span className="as_product_detail">
                                                    <span><img src="assets/images/svg/calendar.svg" alt="" /> 14/01/2021</span>
                                                    <span>Lorem ipsum dolor sit amet, consectetur.</span>
                                                </span>
                                            </a>
                                        </li>
                                        <li className="as_product">
                                            <a href="shop_single.html">
                                                <span className="as_productimg">
                                                    <img src="assets/images/post1.jpg" alt="" />
                                                </span>
                                                <span className="as_product_detail">
                                                    <span><img src="assets/images/svg/calendar.svg" alt="" /> 14/01/2021</span>
                                                    <span>Lorem ipsum dolor sit amet, consectetur.</span>
                                                </span>
                                            </a>
                                        </li>
                                        <li className="as_product">
                                            <a href="shop_single.html">
                                                <span className="as_productimg">
                                                    <img src="assets/images/post1.jpg" alt="" />
                                                </span>
                                                <span className="as_product_detail">
                                                    <span><img src="assets/images/svg/calendar.svg" alt="" /> 14/01/2021</span>
                                                    <span>Lorem ipsum dolor sit amet, consectetur.</span>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>




                            </div>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-12 col-xs-12">
                            <div className="as_blog_box">
                                <div className="as_blog_img">
                                    <a href="blog_detail.html"><img src="assets/images/blog_single1.jpg" alt="" className="img-responsive" /></a>
                                    <span className="as_btn">July 29, 2020</span>
                                </div>
                                <div className="as_blog_detail">
                                    <ul>
                                        <li><a href=""><img src="assets/images/svg/user2.svg" alt="" />By - Admin</a></li>
                                        <li><a href=""><img src="assets/images/svg/comment1.svg" alt="" />0 comments</a></li>
                                        <li><a href=""><img src="assets/images/svg/view1.svg" alt="" />0 views</a></li>
                                    </ul>
                                    <h4 className="as_subheading"><a href=""> Consectetur adipiscing elit sedeius mod tempor incididunt ut labore.</a></h4>
                                    <p className="as_font14 as_margin0">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy that walks through our doors.</p>
                                    <div className="as_padderTop30">
                                        <a href="" className="as_btn">Read More</a>
                                    </div>
                                </div>
                            </div>
                            <div className="as_blog_box">
                                <div className="as_blog_img">
                                    <a href="blog_detail.html"><img src="assets/images/blog_single4.jpg" alt="" className="img-responsive" /></a>
                                    <span className="as_btn">July 29, 2020</span>
                                </div>
                                <div className="as_blog_detail">
                                    <ul>
                                        <li><a href=""><img src="assets/images/svg/user2.svg" alt="" />By - Admin</a></li>
                                        <li><a href=""><img src="assets/images/svg/comment1.svg" alt="" />0 comments</a></li>
                                        <li><a href=""><img src="assets/images/svg/view1.svg" alt="" />0 views</a></li>
                                    </ul>
                                    <h4 className="as_subheading"><a href=""> Consectetur adipiscing elit sedeius mod tempor incididunt ut labore.</a></h4>
                                    <p className="as_font14 as_margin0">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy that walks through our doors.</p>
                                    <div className="as_padderTop30">
                                        <a href="" className="as_btn">Read More</a>
                                    </div>
                                </div>
                            </div>
                            <div className="as_blog_box">
                                <div className="as_blog_img">
                                    <a href=""><img src="assets/images/blog_single2.jpg" alt="" className="img-responsive" /></a>
                                    <span className="as_btn">July 29, 2020</span>
                                </div>
                                <div className="as_blog_detail">
                                    <ul>
                                        <li><a href=""><img src="assets/images/svg/user2.svg" alt="" />By - Admin</a></li>
                                        <li><a href=""><img src="assets/images/svg/comment1.svg" alt="" />0 comments</a></li>
                                        <li><a href=""><img src="assets/images/svg/view1.svg" alt="" />0 views</a></li>
                                    </ul>
                                    <h4 className="as_subheading"><a href=""> Consectetur adipiscing elit sedeius mod tempor incididunt ut labore.</a></h4>
                                    <p className="as_font14 as_margin0">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy that walks through our doors.</p>
                                    <div className="as_padderTop30">
                                        <a href="" className="as_btn">Read More</a>
                                    </div>
                                </div>
                            </div>
                            <div className="as_blog_box as_margin0">
                                <div className="as_blog_img">
                                    <a href="blog_detail.html"><img src="assets/images/blog_single3.jpg" alt="" className="img-responsive" /></a>
                                    <span className="as_btn">July 29, 2020</span>
                                </div>
                                <div className="as_blog_detail">
                                    <ul>
                                        <li><a href=""><img src="assets/images/svg/user2.svg" alt="" />By - Admin</a></li>
                                        <li><a href=""><img src="assets/images/svg/comment1.svg" alt="" />0 comments</a></li>
                                        <li><a href=""><img src="assets/images/svg/view1.svg" alt="" />0 views</a></li>
                                    </ul>
                                    <h4 className="as_subheading"><a href=""> Consectetur adipiscing elit sedeius mod tempor incididunt ut labore.</a></h4>
                                    <p className="as_font14 as_margin0">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy that walks through our doors.</p>
                                    <div className="as_padderTop30">
                                        <a href="" className="as_btn">Read More</a>
                                    </div>
                                </div>
                            </div>
                            <div className="as_pagination as_padderTop50">
                                <ul className="text-right">
                                    <li className="as_prev"><span>prev</span></li>
                                    <li className="as_active"><a href="">1</a></li>
                                    <li><a href="">2</a></li>
                                    <li><a href="">3</a></li>
                                    <li className="as_next"><a href="">next</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}

export default Blogs