import React from 'react'
// import {style} from '../Assets/style'
// import Logo from '../dailykundali_logo.png';
// import logo from '../dailykundali_logo.png';
import Map from '../Assets/images/svg/map (1).svg';
import Phone from '../Assets/images/svg/phone.svg';
import Mail from '../Assets/images/svg/mail.svg';
// import ListGroup from 'react-bootstrap/ListGroup';


function footer() {
    return (
        <div >
            <section className="as_footer_wrapper as_padderTop80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="as_footer_inner as_padderTop10 as_padderBottom40">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="as_footer_widget">
                                            <div className="as_footer_logo">
                                                {/* <a href="#"><img src="https://dailykundli.com/assets/images/logo1.svg " alt=""></a> */}
                                                <h5 style={{ margin: "0px 0px", padding: "0px 0px" }}> Daily Kundli</h5>
                                            </div>
                                            <p>Daily Kundli is a renowned name in the field of Astrology. She is a famous Nadi Astrologer &amp; Vastu Consultant. Often referred to as “Astro Aruna”, she is one of the leading Astrologers in the Country having authority in the field. </p>
                                            <ul className="as_contact_list" style={{ listStyle: "none" }}>
                                                <li>
                                                    <img src={Map} alt="" />
                                                    <p> 53/38, 3rd Floor, Ramjas Road, Karol Bagh, Delhi - 110005 </p>
                                                </li>
                                                <li>
                                                    <img src={Phone} alt="" />
                                                    <p>
                                                        <a href="#">(+91) 9958300960</a>
                                                    </p>
                                                </li>
                                                <li>
                                                    <img src={Mail} alt="" />
                                                    <p>
                                                        <a href="#">astrology@example.com</a>
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-6 col-sm-12">
                                        <div className="as_footer_widget">
                                            <h3 className="as_footer_heading">Quick Links</h3>
                                            <ul>
                                                <li><a href="/liveastrologer"> Live Astrologer</a></li>
                                                <li><a href="/chat-with-astrologer"> Chat With Astrologer</a></li>
                                                <li><a href="/call-to-astrologer"> Call To Astrologer</a></li>
                                                <li><a href="/blogs"> Blog</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-6 col-sm-12">
                                        <div class="as_footer_widget">
                                            <h3 class="as_footer_heading"> Main Menu</h3>
                                            <ul>
                                                <li><a href="about.html">  Free Kundli</a></li>
                                                <li><a href="blog.html"> Kundli Maching</a></li>
                                                <li><a href="service.html"> Horoscope</a></li>
                                                <li><a href="appointment.html"> Live Astrologer</a></li>
                                                <li><a href="blog.html"> Chat With Astrologer</a></li>
                                                <li><a href="contact.html"> Shubh Muharat</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-6 col-sm-12">
                                        <div class="as_footer_widget">
                                            <h3 class="as_footer_heading"> Horoscope</h3>
                                            <ul>
                                                <li><a href="/horoscope2023">  Horoscope 2023</a></li>
                                                <li><a href="/horoscope/todayshoroscope">  Today Horoscope</a></li>
                                                <li><a href="/horoscope/weeklyhoroscope">  weekly Horoscope</a></li>
                                                <li><a href="/horoscope/monthlyhoroscope">  Monthly Horoscope </a></li>
                                                <li><a href="/horoscope/yearlyhoroscope">  Yearly Horoscope</a></li>
                                                <li><a href="/horoscope/dailyhoroscope">  Daily Horoscope</a></li>
                                                <li><a href="/horoscope/tomorrowhoroscope">  Tommorow's Horoscope</a></li>
                                                <li><a href="/horoscope/yesterdayhoroscope">  Yesterday Horoscope</a></li>

                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="as_footer_widget">
                                            <h3 className="as_footer_heading">Our Newsletter</h3>
                                            <p>Lorem ipsum dolor amet, consectetur adipiscing elit,sed eiusmod tempor. </p>
                                            <div className="as_newsletter_wrapper">
                                                <div className="as_newsletter_box">
                                                    <input type="text" name="" id="" className="form-control" placeholder="Email..." />
                                                    <a href="#" className="as_btn">

                                                    </a>
                                                </div>
                                            </div>
                                            <div className="as_login_data">
                                                <label>I agree that my submitted data is
                                                    being collected and stored.
                                                    <input type="checkbox" name="as_remember_me" value="" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="as_copyright_wrapper text-center">
                                <p>Copyright © 2023 Astrology. All Right Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>















        </div>








    )
}
export default footer

