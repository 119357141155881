import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import Banner1 from '../websitebanner01-02.png'
import Banner2 from '../Astrology33.png'
import Banner3 from '../Dailykundali.png'

function slider() {

   
    return (
        //   <>
        // <Carousel activeIndex={index} onSelect={handleSelect} style={{ backgroundImage: "url(https://hucpl.com/Astro_Aruna/assets/images/bg1.jpg)" }} >
        //     <Carousel.Item style={{ paddingTop: "30px", paddingBottom: "30px",paddingLeft:"50px",paddingRight:"50px" }}>
        //         <img
        //             classNameName="d-block w-100"
        //             src={Banner3}
        //             alt="First slide"
        //         />
        //     </Carousel.Item>
        //     <Carousel.Item style={{ paddingTop: "30px", paddingBottom: "30px",paddingLeft:"50px",paddingRight:"50px" }}>
        //         <img
        //             classNameName="d-block w-100"
        //             src={Banner1}
        //             alt="Second slide"
        //         />
        //     </Carousel.Item>
        //     <Carousel.Item style={{ paddingTop: "30px", paddingBottom: "30px" ,paddingLeft:"50px",paddingRight:"50px" }}>
        //         <img
        //             classNameName="d-block w-100"
        //             src={Banner2}
        //             alt="Third slide"
        //         />
        //     </Carousel.Item>
        // </Carousel>
   
   <section style={{backgroundImage: "url(https://hucpl.com/Astro_Aruna/assets/images/bg1.jpg)", height:"20% !important"}}>
        <Carousel fade>
        <Carousel.Item style={{ paddingTop: "30px", paddingBottom: "30px",paddingLeft:"50px",paddingRight:"50px" }}>
          <img
            className="d-block w-100"
            src={Banner1} 
            alt="First slide"
          />
          
        </Carousel.Item>
        <Carousel.Item  style={{ paddingTop: "30px", paddingBottom: "30px",paddingLeft:"50px",paddingRight:"50px" }}>
          <img
            className="d-block w-100"
            src={Banner2} 
            alt="Second slide"
          />
  
        </Carousel.Item>
        <Carousel.Item style={{ paddingTop: "30px", paddingBottom: "30px",paddingLeft:"50px",paddingRight:"50px" }}>
          <img
            className="d-block w-100"
            src={Banner3} 
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
      </section>
     

    )
}

export default slider