import React, { useState } from "react";
// import "../Assets/style.css";

const Form = () => {

    var someDate = new Date();
    var numberOfDaysToAdd = 3;
    var date = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    var defaultValue = new Date(date).toISOString().split("T")[0];


    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: ""
    });

    const updateFormData = event =>
        setFormData({
            ...formData,
            // [event.target.name]: event.target.value
        });

    const { firstName, lastName, email, password } = formData;

    return (
        <form className="as_appointment_form">
            <div className="form-group">
                <input type="text" name="" placeholder="Name" className="form-control" />
            </div>
            <div className="form-group">



                <select className="form-control" name="fee_payment_mode" placeholder="Select Gender" >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                </select>
                {/* <option value={leadData.fee_payment_mode} >--Select--</option>
                                                {
                                                    leadData.fee_payment_mode == "Online" ? 
                                                    <option selected value="Online">Online</option>
                                                    :
                                                    <option value="Online">Online</option>
                                                } */}
                { /*
                                                    leadData.fee_payment_mode == "Cash" ? 
                                                    <option selected value="Cash">Cash</option>
                                                    :
                                                    <option value="Cash">Cash</option>
                                                */ }
                { /*
                                                    leadData.fee_payment_mode == "Swift" ? 
                                                    <option selected value="Swift">Swift</option>
                                                    :
                                                    <option value="Swift">Swift</option>
                                                */ }
                {/* </select> */}
                {/* <input type="text" name="" value="" placeholder="Gender"             className="form-control" /> */}
            </div>




            <div className="form-group">
                <label>DOB</label>
                <input
                    id="dateRequired"
                    type="date"
                    name="dateRequired"
                    // defaultValue={defaultValue}
                    className="form-control"
                />

            </div>

            <div className="form-group">

                <input type="time" id="appt" name="appt"
                    min="09:00" max="18:00" required className="form-control" />

            </div>

            <div className="form-group">
                <input type="text" name="" placeholder="Birth Place" className="form-control" />
            </div>


            <button className="as_btn" style={{ marginBottom: "2%" }}>Generate Horoscope</button>
            <br />
        </form>



    );
};

export default Form;
