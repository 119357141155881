import React from 'react'
import H1 from '../Assets/images/svg/h1.svg';
import H2 from '../Assets/images/svg/h2.svg';
import H3 from '../Assets/images/svg/h3.svg';
import H4 from '../Assets/images/svg/h4.svg';
import H5 from '../Assets/images/svg/h5.svg';
import H6 from '../Assets/images/svg/h6.svg';
import H7 from '../Assets/images/svg/h7.svg';
import H8 from '../Assets/images/svg/h8.svg';
import H9 from '../Assets/images/svg/h9.svg';
import H10 from '../Assets/images/svg/h10.svg';
import H11 from '../Assets/images/svg/h11.svg';
import H12 from '../Assets/images/svg/h12.svg';
import { Link, } from 'react-router-dom';

function weeklyhoroscope() {
    return (


        <div>

            <section     className="as_breadcrum_wrapper">
                <div     className="container">
                    <div     className="row">
                        <div     className="col-lg-12 text-center" style={{ textAlign: "center" }}>
                            <h1 style={{ paddingTop: "5px" }}> WEEKLY HOROSCOPE</h1>

                            <ul     className="breadcrumb">
                                <li><a href="/">Home</a></li>

                            </ul>
                        </div>
                    </div>
                </div>
            </section>



            <div     className='row'>

                <h3 style={{ textAlign: "center", paddingTop: "2%" }}>Weekly Horoscope</h3>

                <p style={{ textAlign: "justify", padding: "2%", paddingLeft: "10%", paddingRight: "10%" }}>

                    Looking for your weekly horoscope? Astrotalk can help you put an end to your search. On Astrotalk, the astrologers, on a regular basis, caters horoscopes, including weekly horoscopes, that are prepared after considering the movement of the planets in Vedic astrology. This movement of the Vedic planets can be both positive and negative for the native, and the work of the horoscopes for the week ahead is to highlight their influences, their effect, and the associated remedies that one can adopt to ensure that you are safeguarded from the negative effect of the planets.
                    <br />
                </p>





            </div>

            <div     className='row'>



                <div     className='col-md-6 col-12' style={{ paddingLeft: "7%" }}>
                    <div     className="card2 card mb-3" style={{ maxWidth: "540px", boxShadow: "0 0 10px 2px #dbdbdb" }}>
                        <div     className="row g-0 align-items-center">
                            <div     className="col-sm-4 col-5">

                                <div     className="as_sign_box text-center" >

                                    <a href="">
                                        <span     className="as_sign" >
                                            <img src={H1} alt="" />
                                        </span>
                                        <div>
                                            <h5>Aries</h5>
                                            <p>Mar 21 - Apr 19</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div     className="col-sm-8 col-7">
                                <div     className="card-body">
                                    <h5     className="card-title">Aries</h5>
                                    <p     className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                    {/* <p           className="card-text d-none d-sm-block"><sma  className  className  classNameNameName="text-muted">Last updated 3 mins ago</small></p> */}
                                    <Link to='/horoscope/weeklyhoroscope/arieshoroscope'>Read More </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div     className='col-md-6 col-12' style={{ paddingRight: "7%" }}>
                    <div     className="card2 card mb-3" style={{ maxWidth: "540px", boxShadow: "0 0 10px 2px #dbdbdb" }}>
                        <div     className="row g-0 align-items-center">
                            <div     className="col-sm-4 col-5">

                                <div     className="as_sign_box text-center">
                                    <a href="#">
                                        <span     className="as_sign">
                                            <img src={H2} alt="" />
                                        </span>
                                        <div>
                                            <h5>Taurus</h5>
                                            <p>Mar 21 - Apr 19</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div     className="col-sm-8 col-7">
                                <div     className="card-body">
                                    <h5     className="card-title">Taurus Weekly Horoscope </h5>
                                    <p     className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                    {/* <p           className="card-text d-none d-sm-block"><sma  className  className  classNameNameName="text-muted">Last updated 3 mins ago</small></p> */}
                                    <Link to='/horoscope/weeklyhoroscope/taurushoroscope'>Read More </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>

            <div     className='row'>



                <div     className='col-md-6 col-12' style={{ paddingLeft: "7%" }}>
                    <div     className="card2 card mb-3" style={{ maxWidth: "540px", boxShadow: "0 0 10px 2px #dbdbdb" }}>
                        <div     className="row g-0 align-items-center">
                            <div     className="col-sm-4 col-5">

                                <div     className="as_sign_box text-center">
                                    <a href="#">
                                        <span     className="as_sign">
                                            <img src={H3} alt="" />
                                        </span>
                                        <div>
                                            <h5>Gemini</h5>
                                            <p>Mar 21 - Apr 19</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div     className="col-sm-8 col-7">
                                <div     className="card-body">
                                    <h5     className="card-title">Gemini Weekly Horoscope</h5>
                                    <p     className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                    {/* <p           className="card-text d-none d-sm-block"><sma  className  className  classNameNameName="text-muted">Last updated 3 mins ago</small></p> */}
                                    <Link to='/horoscope/weeklyhoroscope/geminihoroscope'>Read More </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div     className='col-md-6 col-12' style={{ paddingRight: "7%" }}>
                    <div     className="card2 card mb-3" style={{ maxWidth: "540px", boxShadow: "0 0 10px 2px #dbdbdb" }}>
                        <div     className="row g-0 align-items-center">
                            <div     className="col-sm-4 col-5">

                                <div     className="as_sign_box text-center">
                                    <a href="#">
                                        <span     className="as_sign">
                                            <img src={H4} alt="" />
                                        </span>
                                        <div>
                                            <h5>Cancer</h5>
                                            <p>Mar 21 - Apr 19</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div     className="col-sm-8 col-7">
                                <div     className="card-body">
                                    <h5     className="card-title">Cancer Weekly Horoscope</h5>
                                    <p     className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                    {/* <p           className="card-text d-none d-sm-block"><sma  className  className  classNameNameName="text-muted">Last updated 3 mins ago</small></p> */}
                                    <Link to='/horoscope/weeklyhoroscope/cancerhoroscope'>Read More </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
            <div     className='row'>



                <div     className='col-md-6 col-12' style={{ paddingLeft: "7%" }}>
                    <div     className="card2 card mb-3" style={{ maxWidth: "540px", boxShadow: "0 0 10px 2px #dbdbdb" }}>
                        <div     className="row g-0 align-items-center">
                            <div     className="col-sm-4 col-5">
                                <div     className="as_sign_box text-center">
                                    <a href="#">
                                        <span     className="as_sign">
                                            <img src={H5} alt="" />
                                        </span>
                                        <div>
                                            <h5>Leo</h5>
                                            <p>Mar 21 - Apr 19</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div     className="col-sm-8 col-7">
                                <div     className="card-body">
                                    <h5     className="card-title">Leo Weekly Horoscope</h5>
                                    <p     className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                    {/* <p           className="card-text d-none d-sm-block"><sma  className  className  classNameNameName="text-muted">Last updated 3 mins ago</small></p> */}
                                    <Link to='/horoscope/weeklyhoroscope/leohoroscope'>Read More </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div     className='col-md-6 col-12' style={{ paddingRight: "7%" }}>
                    <div     className="card2 card mb-3" style={{ maxWidth: "540px", boxShadow: "0 0 10px 2px #dbdbdb" }}>
                        <div     className="row g-0 align-items-center">
                            <div     className="col-sm-4 col-5">

                                <div     className="as_sign_box text-center" >
                                    <a href="#">
                                        <span     className="as_sign">
                                            <img src={H6} alt="" style={{ paddingBottom: "2%" }} />
                                        </span>
                                        <div>
                                            <h5>Virgo </h5>
                                            <p>Mar 21 - Apr 19</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div     className="col-sm-8 col-7">
                                <div     className="card-body">
                                    <h5     className="card-title">Virgo Weekly Horoscope</h5>
                                    <p     className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                    {/* <p           className="card-text d-none d-sm-block"><sma  className  className  classNameNameName="text-muted">Last updated 3 mins ago</small></p> */}
                                    <Link to='/horoscope/weeklyhoroscope/virgohoroscope'>Read More </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
            <div     className='row'>



                <div     className='col-md-6 col-12' style={{ paddingLeft: "7%" }}>
                    <div     className="card2 card mb-3" style={{ maxWidth: "540px", boxShadow: "0 0 10px 2px #dbdbdb" }}>
                        <div     className="row g-0 align-items-center">
                            <div     className="col-sm-4 col-5">
                                <div     className="as_sign_box text-center">
                                    <a href="#">
                                        <span     className="as_sign">
                                            <img src={H7} alt="" />
                                        </span>
                                        <div>
                                            <h5>Libra</h5>
                                            <p>Mar 21 - Apr 19</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div     className="col-sm-8 col-7">
                                <div     className="card-body">
                                    <h5     className="card-title">Libra Weekly Horoscope</h5>
                                    <p     className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                    {/* <p           className="card-text d-none d-sm-block"><sma  className  className  classNameNameName="text-muted">Last updated 3 mins ago</small></p> */}
                                    <Link to='/horoscope/weeklyhoroscope/librahoroscope'>Read More </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div     className='col-md-6 col-12' style={{ paddingRight: "7%" }}>
                    <div     className="card2 card mb-3" style={{ maxWidth: "540px", boxShadow: "0 0 10px 2px #dbdbdb" }}>
                        <div     className="row g-0 align-items-center">
                            <div     className="col-sm-4 col-5">
                                <div     className="as_sign_box text-center">
                                    <a href="#">
                                        <span     className="as_sign">
                                            <img src={H8} alt="" />
                                        </span>
                                        <div>
                                            <h5>Scorpio</h5>
                                            <p>Mar 21 - Apr 19</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div     className="col-sm-8 col-7">
                                <div     className="card-body">
                                    <h5     className="card-title">Scorpio Weekly Horoscope</h5>
                                    <p     className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                    {/* <p           className="card-text d-none d-sm-block"><sma  className  className  classNameNameName="text-muted">Last updated 3 mins ago</small></p> */}
                                    <Link to='/horoscope/weeklyhoroscope/scorpiohoroscope'>Read More </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
            <div     className='row'>



                <div     className='col-md-6 col-12' style={{ paddingLeft: "7%" }}>
                    <div     className="card2 card mb-3" style={{ maxWidth: "540px", boxShadow: "0 0 10px 2px #dbdbdb" }}>
                        <div     className="row g-0 align-items-center">
                            <div     className="col-sm-4 col-5">
                                <div     className="as_sign_box text-center">
                                    <a href="#">
                                        <span     className="as_sign">
                                            <img src={H9} alt="" />                                   </span>
                                        <div>
                                            <h5>Sagittairus </h5>
                                            <p>Mar 21 - Apr 19</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div     className="col-sm-8 col-7">
                                <div     className="card-body">
                                    <h5     className="card-title">Sagittairus Weekly Horoscope</h5>
                                    <p     className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                    {/* <p           className="card-text d-none d-sm-block"><sma  className  className  classNameNameName="text-muted">Last updated 3 mins ago</small></p> */}
                                    <Link to='/horoscope/weeklyhoroscope/sagittairushoroscope'>Read More </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div     className='col-md-6 col-12' style={{ paddingRight: "7%" }}>
                    <div     className="card2 card mb-3" style={{ maxWidth: "540px", boxShadow: "0 0 10px 2px #dbdbdb" }}>
                        <div     className="row g-0 align-items-center">
                            <div     className="col-sm-4 col-5">
                                <div     className="as_sign_box text-center">
                                    <a href="#">
                                        <span     className="as_sign">
                                            <img src={H10} alt="" />
                                        </span>
                                        <div>
                                            <h5>Capricorn</h5>
                                            <p>Mar 21 - Apr 19</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div     className="col-sm-8 col-7">
                                <div     className="card-body">
                                    <h5     className="card-title">Capricorn Weekly Horoscope</h5>
                                    <p     className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                    {/* <p           className="card-text d-none d-sm-block"><sma  className  className  classNameNameName="text-muted">Last updated 3 mins ago</small></p> */}
                                    <Link to='/horoscope/weeklyhoroscope/capricornhoroscope'>Read More </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
            <div     className='row'>



                <div     className='col-md-6 col-12' style={{ paddingLeft: "7%" }}>
                    <div     className="card2 card mb-3" style={{ maxWidth: "540px", boxShadow: "0 0 10px 2px #dbdbdb" }}>
                        <div     className="row g-0 align-items-center">
                            <div     className="col-sm-4 col-5">
                                <div     className="as_sign_box text-center">
                                    <a href="#">
                                        <span     className="as_sign">
                                            <img src={H11} alt="" />
                                        </span>
                                        <div>
                                            <h5>Aquarius </h5>
                                            <p>Mar 21 - Apr 19</p>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div     className="col-sm-8 col-7">
                                <div     className="card-body">
                                    <h5     className="card-title">Aquarius Weekly Horoscope</h5>
                                    <p     className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                    {/* <p           className="card-text d-none d-sm-block"><sma  className  className  classNameNameName="text-muted">Last updated 3 mins ago</small></p> */}
                                    <Link to='/horoscope/weeklyhoroscope/aquariushoroscope'>Read More </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div     className='col-md-6 col-12' style={{ paddingRight: "7%" }}>
                    <div     className="card2 card mb-3" style={{ maxWidth: "540px", boxShadow: "0 0 10px 2px #dbdbdb" }}>
                        <div     className="row g-0 align-items-center">
                            <div     className="col-sm-4 col-5">
                                <div     className="as_sign_box text-center">
                                    <a href="">
                                        <span     className="as_sign">
                                            <img src={H12} alt="" />
                                        </span>
                                        <div>
                                            <h5>Pisces</h5>
                                            <p>Mar 21 - Apr 19</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div     className="col-sm-8 col-7">
                                <div     className="card-body">
                                    <h5     className="card-title">Pisces Weekly Horoscope</h5>
                                    <p     className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                    {/* <p           className="card-text d-none d-sm-block"><sma  className  className  classNameNameName="text-muted">Last updated 3 mins ago</small></p> */}
                                    <Link to='/horoscope/weeklyhoroscope/pisceshoroscope'>Read More </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>







            <div     className='row' style={{ textAlign: "justify", padding: "1%", paddingLeft: "10%", paddingRight: "10%", }}>

                <h5 style={{ paddingTop: "2%" }}> Understanding Today horoscope</h5>

                <p >

                    If you are a follower of astrology, you would know that each zodiac sign in astrology is ruled by a planet. For example, the Moon is the ruler of Cancer, and Mars is the ruler of both Aries and Scorpio zodiac signs. Similarly, Gemini is ruled by Mercury and so on. It’s the negative and positive state of these planets that affects your zodiac sign and thus your future.

                    For example, Taurus is ruled by the planet Venus. In astrology, Mars, Jupiter, and Ketu are the enemy of the planet Venus. Hence, if Venus is placed in any house of your Kundli with Mars, Jupiter or Ketu, then the characteristics of that house may turn negative for you and reflect on your Horoscope today.



                </p>








            </div>

            <section     className="as_whychoose_wrapper" style={{ cursor: "pointer" }}>
                <div     className="container-fluid">
                    <div     className="row">
                        <div     className="col-lg-12 col-md-12 text-center">
                            <h1     className="as_heading">Want to know more about kundli</h1>

                            <p     className="as_font14 as_padderTop20 as_padderBottom50">Our professional and experienced Astrologers study the movements of the planets, sun, moon, and <br />stars and predict as to how these movements are influencing and/or can have an influence on people's lives.</p>
                        </div>
                        <div     className="col-lg-12">
                            <ul     className="as_choose_ul">



                                <li style={{ paddingLeft: "10%" }}>

                                    <a href="/"> <button     className="as_btn" href="/kundlimatching" style={{ marginLeft: "50%" }}>Click Here </button></a>

                                </li>
                                <li style={{ paddingLeft: "25%" }}>

                                    <a href="">   <button     className="as_btn" style={{ marginLeft: "50%", width: "20%" }}>Chat With Astrologer </button></a>

                                </li>
                                <li style={{ paddingLeft: "30%" }}>

                                    <a href="">    <button     className="as_btn" style={{ marginLeft: "50%" }}>Talk To Astrologer </button></a>

                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <div     className="row" style={{ marginTop: "2%" }}>
                <h4 style={{ textAlign: "center", margingTop: "5%", marginBottom: "5%" }}>FAQS WEEKLY HOROSCOPE</h4>

                <h5 style={{ paddingLeft: "10%" }}>
                    Q 1. Can today’s horoscope predict life-changing events for a zodiac sign?
                </h5>
                <p style={{ paddingLeft: "10%", paddingRight: "10%", textAlign: "justify" }}>
                    Today’s horoscope can tell a lot about all the twelve zodiac signs. You can know how negative things may get or how positive your day will be. And yes, it can definitely predict the big events that may occur in the day.
                </p>

                < h5 style={{ paddingLeft: "10%" }}>
                    Q 2. What is the duration of planetary transits?
                </h5>
                <p style={{ paddingLeft: "10%", paddingRight: "10%", textAlign: "justify" }}>
                    The longer the transit period of the planet, the more influential it will be in your horoscope or birth chart. Planetary transits, direct or retrograde, last anywhere from a few days (for the Moon) to 2 to 2.5 years (for Saturn).

                </p>

                < h5 style={{ paddingLeft: "10%" }}>
                    Q 3.Which planet is favorable for love in 2023?
                </h5>
                <p style={{ paddingLeft: "10%", paddingRight: "10%", textAlign: "justify" }}>
                    For an individual, if the Jupiter transit in 2023 occurs in the 1st and 7th house, it shall provide enthusiasm and enhancement to one's outlook towards all relationships along with personal progress. You will more willingly accept supportive individuals into your life who help you thrive in hard times, which often include a love affair.
                </p>
                < h5 style={{ paddingLeft: "10%" }}>
                    Q 4. Which house is favorable for Jupiter in 2023?
                </h5>
                <p style={{ paddingLeft: "10%", paddingRight: "10%", textAlign: "justify" }}>
                    Transit of Jupiter in 2023 will give good results when placed in 1st, 5th, 8th, 9th, or 12th houses. However, the 6th, 7th, and 10th houses are bad houses for it. Moreover, Jupiter might give inauspicious results when Venus or Mercury is in the tenth house of the birth chart of a person.
                </p>

                < h5 style={{ paddingLeft: "10%" }}>
                    Q 5. What is Dasha in Kundli analysis?
                </h5>
                <p style={{ paddingLeft: "10%", paddingRight: "10%", textAlign: "justify" }}>
                    Dasha is a vital phase of a planet in the life of the native. And according to astrology, there are 43 different types of Dasha systems. When the planet is powerful or settled in its exaltation sign, the Dasha is known as the Poorna Dasha. However, if the planet is powerless or in poor condition, the Dasha is recognized as the Rikta Dasha.

                </p>










            </div>


        </div>

    )
}

export default weeklyhoroscope